/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, FlexCol, Icons, FlexRow } from 'sarsaparilla';
import { IImage } from '../../../../shared/types/asset/media';
import { Globals } from '../../../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import { reduceAndRestructure } from '../../../../shared/utils/asset';
import TargetedEvent = Globals.TargetedEvent;
import { addChange, clearChange, getRef } from '../../../../actions/changes';
import {
    IInputPickerInput,
    InputPickerTypes,
    InputPicker,
} from '../../../../components/StewardAsset/StewardAssetEdit/InputPicker';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import emptyFunction = Globals.emptyFunction;
import { determineNewInvalidItems } from '../../../../shared/utils/validation';
import { isValidNumeric, isValidURL } from '../../../../shared/utils/string';

const imageValidation = {
    url: (name: string) => name === '' || name === void 0 || isValidURL(name),
    height: (number: any) => number === '' || number === void 0 || isValidNumeric(number),
    width: (number: any) => number === '' || number === void 0 || isValidNumeric(number),
};

type ImageEditProps = {
    value?: IImage;
    mediaType?: string;
    onRemove?: AnyFunction;
    getRef: AnyFunction;
    addChange: AnyFunction;
    clearChange: AnyFunction;
    onInvalidItemsChange?: AnyFunction;
    onChange?: AnyFunction;
};

type ImageEditState = {
    record: any;
    ref: any;
    invalidItems: any[];
    deleted: boolean;
};

class ImageEdit extends Component<ImageEditProps, ImageEditState> {
    readonly inputs: IInputPickerInput[] = [
        {
            type: InputPickerTypes.TEXT,
            label: 'Image URL',
            required: false,
            field: 'url',
            isValid: (val) => imageValidation.url(val),
            errorText: 'Image URL is invalid',
        },
        {
            type: InputPickerTypes.FILE,
            label: 'Upload Image',
            required: false,
            fileTypes: 'image/jpeg, image/png',
            field: 'image',
        },
        {
            type: InputPickerTypes.TEXT,
            label: 'Image Title',
            required: true,
            field: 'title',
        },
        {
            type: InputPickerTypes.TEXT,
            label: 'Image Subtitle',
            field: 'subtitle',
        },
        {
            type: InputPickerTypes.TEXT,
            label: 'Image Credits',
            field: 'credits',
        },
        {
            type: InputPickerTypes.BLANK,
            field: 'spacing-for-dimensions',
            label: void 0,
        },
        {
            type: InputPickerTypes.TEXT,
            label: 'Image Width',
            field: 'width',
            isValid: (val) => imageValidation.width(val),
            errorText: 'Image Width is invalid',
        },
        {
            type: InputPickerTypes.TEXT,
            label: 'Image Height',
            field: 'height',
            isValid: (val) => imageValidation.height(val),
            errorText: 'Image Height is invalid',
        },
        {
            type: InputPickerTypes.TEXTBOX,
            label: 'Image Description',
            fullWidth: true,
            field: 'description',
        },
        {
            type: InputPickerTypes.CHECKBOX,
            label: 'Primary/Header Image',
            field: 'isPrimary',
        },
        {
            type: InputPickerTypes.CHECKBOX,
            label: 'Use For Preview',
            field: 'isPreview',
        },
        {
            type: InputPickerTypes.CHECKBOX,
            label: 'Include In Gallery',
            field: 'isGallery',
        },
    ];

    constructor(props) {
        super(props);

        const { value: record, getRef, mediaType } = props;
        const paths = ['title'];
        const ref = getRef(
            mediaType,
            { id: record.id, type: 'Image' },
            void 0,
            reduceAndRestructure(paths, record)
        );
        this.state = {
            record,
            ref,
            invalidItems: [],
            deleted: false,
        };
    }

    handleChange = (event: TargetedEvent<{ dataset: { field: any } }>) => {
        const {
            dataset: { field },
            value: fieldValue,
        } = event.target;
        const {
            props: {
                addChange,
                onChange = emptyFunction,
                onInvalidItemsChange = emptyFunction,
            },
            state: { ref, invalidItems },
        } = this;

        // Handle special case for height and width
        const value =
            field === 'height' || field === 'width'
                ? parseInt(fieldValue, 10)
                : fieldValue;

        const change = { [field]: value };
        const newInvalids = determineNewInvalidItems(
            imageValidation,
            field,
            value,
            invalidItems,
            'Image'
        );
        if (newInvalids) {
            this.setState({ invalidItems: newInvalids }, () => {
                onInvalidItemsChange(newInvalids);
            });
        }
        this.setState(
            (prevState) => {
                const record = { ...prevState.record, ...change };
                onChange(record);
                return { ...prevState, record };
            },
            () => addChange(ref, change)
        );
    };

    doRemove = () => {
        const {
            props: {
                value: { id, _key },
                addChange,
                clearChange,
                onRemove,
                onInvalidItemsChange,
            },
            state: { ref, invalidItems },
        } = this;
        id ? addChange(ref, { _delete: true }) : clearChange(ref);
        onRemove(_key);
        this.setState({ deleted: true });
        const newInvalids = invalidItems.map((i) => ({ ...i, _delete: true }));
        this.setState({ invalidItems: newInvalids });
        onInvalidItemsChange(newInvalids);
    };

    render() {
        const { record, ref } = this.state;

        return (
            <div className="section-box">
                <Button
                    appearance="subtle-danger"
                    onClick={this.doRemove}
                    className="delete-button"
                    iconBeforeElement={<Icons.IconClose />}
                />
                <FlexRow>
                    {this.inputs.map((input: IInputPickerInput) => (
                        <FlexCol key={input.field} md={6}>
                            {InputPicker(input, ref, record, this.handleChange)}
                        </FlexCol>
                    ))}
                </FlexRow>
            </div>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRef,
            addChange,
            clearChange,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ImageEdit);
