/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import moment from 'moment';
import { DatePicker, momentToCalendarDate } from 'sarsaparilla';
import {
    getCssClassesFromProps,
    getLabelFromProps,
    IWrappedInputsBaseProps,
} from './wrappedInputs';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import '../../../../scss/components/StewardAssetEditPage/_DateInput.scss';

interface DateInputProps extends IWrappedInputsBaseProps {
    value?: string;
    fullWidth?: boolean;
}

class AssetEditDateInput extends Component<DateInputProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            props: {
                field,
                label: propsLabel,
                required,
                fullWidth,
                value = '',
                onChange,
            },
        } = this;
        const id = `mod_${field.replace('.', '_')}`;
        const label = getLabelFromProps(propsLabel, id);
        const css = getCssClassesFromProps(required, fullWidth, 'date-input-wrapper');
        const dateValue = momentToCalendarDate(moment(value));

        return (
            <div className={css}>
                {label}
                <DatePicker
                    id={id}
                    defaultValue={dateValue}
                    numberOfMonths={1}
                    showDefaultInputIcon
                    onChange={(value, validation) => {
                        if (value && !validation.isInvalid) {
                            onChange(moment.utc(value.toString(), 'YYYY-MM-DD'));
                        }
                    }}
                />
            </div>
        );
    }
}

export default AssetEditDateInput;
