/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Button } from 'sarsaparilla';
import '../../scss/components/_InlinedLinkButton.scss';

type InlineLinkButtonProps = {
    url: string;
    textAfter: string;
    text: string;
    dividerOptions?: {
        index: number;
        stopAt: number;
        text: string;
    };
};

export function InlineLinkButton(props: InlineLinkButtonProps) {
    const { url, text, textAfter, dividerOptions } = props;
    return (
        <div className="download-buttons-inline">
            <div className="button-wrapper">
                <Button
                    appearance="link"
                    href={`${url}`}
                    screenReaderTextAfter={`${textAfter}`}
                >
                    {text}
                </Button>
            </div>
            <div className="divider-wrapper">
                <a className="icon-image" aria-label="Icon image" />
                {dividerOptions && dividerOptions.index !== dividerOptions.stopAt && (
                    <span className="divider">{dividerOptions.text}</span>
                )}
            </div>
        </div>
    );
}

export default InlineLinkButton;
