/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Heading, HelmetWrapper, Notification, SkipNavContent } from 'sarsaparilla';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InlineLinkButton from '../shared/components/InlineLinkButton';
import { getCurrentUser } from '../reducers/currentUser';
import { Globals } from '../shared/globals';
import jsonDownloadURL = Globals.jsonDownloadURL;
import csvDownloadURL = Globals.csvDownloadURL;
import { getHost } from '../../dev/env';
import '../scss/containers/_DownloadPage.scss';
import RidbLink from '../shared/components/RidbLink';

function DownloadPage() {
    const rootUrl = getHost(process.env['ENV'], true);
    const startYear = 2006;
    const endYear = 2024;
    const years = new Array(endYear - startYear)
        .fill(0)
        .map((_, index) => startYear + index);
    const recDataDownloads = {
        [csvDownloadURL]: 'CSV Format',
        [jsonDownloadURL]: 'JSON Format',
    };

    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB Recreation Data" />
            <main
                role="main"
                id="developer-portal-download-page"
                className="download-page"
            >
                <div className="body-wrap">
                    <div>
                        <div className="body-section body-section">
                            <Heading headingLevel={1} className="recreation-data-heading">
                                RIDB Recreation Data
                            </Heading>
                            <p>
                                To download all the RIDB recreation area, facility, and
                                site level data in CSV or JSON format, please select the
                                link below. <em>Updated Daily.</em>
                            </p>
                            {Object.keys(recDataDownloads).map(
                                (link: string, index: number) => (
                                    <InlineLinkButton
                                        key={index}
                                        url={link}
                                        textAfter={`RIDB Recreation Data ${recDataDownloads[link]}`}
                                        text={recDataDownloads[link]}
                                        dividerOptions={{
                                            index,
                                            stopAt:
                                                Object.keys(recDataDownloads).length - 1,
                                            text: '|',
                                        }}
                                    />
                                )
                            )}
                        </div>
                        <div className="body-section body-section">
                            <Heading headingLevel={1} className="recreation-data-heading">
                                RIDB/{process.env['SITE_NAME']} Historical Reservation
                                Data
                            </Heading>
                            <p>
                                Historical Reservation data is available for download from
                                fiscal year {startYear} to {endYear}. As of October 12th,
                                2018 future Historical Reservation data will be available
                                in a new format. Please select the year to download a
                                compressed CSV file.
                            </p>
                            <p>
                                <em>Updated May 2024.</em>
                            </p>
                            {years.map((year: number, index: number) => (
                                <InlineLinkButton
                                    key={index}
                                    url={`${rootUrl}/downloads/reservations${year}.zip`}
                                    textAfter={`RIDB/${process.env['SITE_NAME']} Historical Reservation Data Fiscal Year ${year}`}
                                    dividerOptions={{
                                        index,
                                        stopAt: years.length - 1,
                                        text: '|',
                                    }}
                                    text={`FY ${year}`}
                                />
                            ))}
                        </div>
                        <div className="body-section notice-section">
                            <Notification type="info" title="Notice:" headingLevel={3}>
                                <ul>
                                    <li>Data sets may be large</li>
                                    <li>
                                        For more information on how to use the RIDB data,
                                        please visit our&nbsp;
                                        <RidbLink to="/docs">API Documentation</RidbLink>
                                    </li>
                                    <li>
                                        <strong>
                                            By downloading this data, you are agreeing to
                                            the&nbsp;
                                            <RidbLink to="/access-agreement-ridb">
                                                RIDB API Access Agreement.
                                            </RidbLink>
                                        </strong>
                                    </li>
                                </ul>
                            </Notification>
                        </div>
                    </div>
                </div>
            </main>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPage);
