/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { IBaseKeyConfig, Translator } from './translator';
import { Globals } from '../../globals';
import { decodeOrganizationKeys } from '../../constants/asset/organization';
import AnyObject = Globals.AnyObject;
import NotVoid = Globals.NotVoid;

const keyConfig: IBaseKeyConfig = {
    main: {
        decode: decodeOrganizationKeys,
        encode: void 0,
    },
};

class Organization extends Translator {
    decode = (record: AnyObject) => {
        const { decode: decodeKeys } = this.keys.main;
        return this.translate(decodeKeys, record, { _key: uniqueId('org-') });
    };

    encode = (record: AnyObject) => {
        return void 0;
    };
}

export default new Organization(keyConfig);
