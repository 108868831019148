/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { HelmetWrapper, SkipNavContent } from 'sarsaparilla';
import { Globals } from '../shared/globals';
import { getCurrentUser } from '../reducers/currentUser';
import SearchForm from './SearchForm';
import redirect = Globals.redirect;
import getAssetPath = Globals.getAssetPath;
import RidbLink from '../shared/components/RidbLink';
import '../scss/containers/_LandingPage.scss';

/**
 * This is usually a violation of how site-kit works, but in this case, RIDB is only ever
 * r1s and no other tenant needs these partner icons, so there's no point in making them
 * a site-kit export and creating empty files for every other tenant
 */
import * as PartnerIcons from '../../../site-kit/r1s/src/components/Icons/Partner/PartnerIcons';

type LandingPageProps = {
    history?: any;
};

function LandingPage(props: LandingPageProps) {
    const goToSearch = () => redirect(props.history, '/search');
    const logoClasses = `logo agency-grid-logo`;
    const background = getAssetPath('img/ridb-bg.jpg');
    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB Home" />
            <main role="main" id="developer-portal-landing-page" className="landing-page">
                <div
                    style={{ background: `url(${background})` }}
                    className="hero-wrap hero-image image-container"
                >
                    <div className="hero-content-wrapper">
                        <div className="rec-nested-wrap">
                            <div className="hero-content-left">
                                <h2>Recreation Information Database - RIDB</h2>
                                <p>
                                    RIDB is a part of the Recreation One Stop (R1S)
                                    program, which oversees the operation of
                                    Recreation.gov -- a user-friendly, web-based resource
                                    to citizens, offering a single point of access to
                                    information about recreational opportunities
                                    nationwide. The website represents an authoritative
                                    source of information and services for millions of
                                    visitors to federal lands, historic sites, museums,
                                    waterways and other destinations and activities.
                                </p>
                            </div>
                            <div id="dplp-search-form" className="hero-content-right">
                                <div className="search-form">
                                    <h3>Recreation Information Database</h3>
                                    <p>
                                        Search RIDB catalog by state, organization, and
                                        activity.
                                    </p>
                                    <SearchForm onSearch={goToSearch} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data-partners-container agency-grid">
                        <div className="rec-nested-wrap">
                            <div className={logoClasses}>
                                <PartnerIcons.BLM />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.DOT />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.NOAA />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.Smithsonian />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.ForestService />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.USACE />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.FWS />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.NPS />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.TRE />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.TVA />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.DOI />
                            </div>
                            <div className={logoClasses}>
                                <PartnerIcons.NationalArchives />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body-wrap">
                    <div className="rec-nested-wrap">
                        <div className="body-section body-section">
                            <h2>Introduction</h2>
                            <p>
                                Welcome to the{' '}
                                <RidbLink to="/docs">
                                    Recreation Information Database (RIDB)
                                </RidbLink>{' '}
                                Application Programing Interface (API). This API is
                                provided for the purpose of accessing RIDB API endpoints
                                which contain information for federal recreation areas,
                                facilities, campsites, tours, and permits.
                            </p>
                            <p>
                                Federal agencies provide and are responsible for the
                                quality of data contained in the RIDB. Some data may be
                                missing or incomplete. For example, some latitudes and
                                longitudes may be blank. Please be mindful of this when
                                searching for something in particular or applying
                                proximity (radius) filters.By registering to use the API,
                                you are agreeing to comply with all terms and conditions
                                of the&nbsp;
                                <RidbLink to="/access-agreement-ridb">
                                    API Access Agreement
                                </RidbLink>
                                .
                            </p>
                        </div>
                        <div className="body-section body-section">
                            <h2>About the RIDB</h2>
                            <p>
                                The Recreation Information Database (RIDB) provides data
                                resources to citizens, offering a single point of access
                                to information about recreational opportunities
                                nationwide. The RIDB represents an authoritative source of
                                information and services for millions of visitors to
                                federal lands, historic sites, museums, and other
                                attractions/resources. This initiative integrates multiple
                                Federal channels and sources about recreation
                                opportunities into a one-stop, searchable database of
                                recreational areas nationwide.
                            </p>
                        </div>
                        <div className="body-section body-section">
                            <h2>Intended Audience</h2>
                            <p>
                                This website is intended for the general public. It
                                provides instructions on how to extract data from the
                                RIDB.
                            </p>
                            <p>
                                The data is provided at no cost in order to encourage wide
                                distribution and regular updates to different
                                publications, databases, and websites, so customers can
                                receive correct information from many different systems.
                                As noted elsewhere on this site, by registering to use the
                                API and/or downloading the RIDB data, you are agreeing to
                                be bound by the terms and conditions of the&nbsp;
                                <RidbLink to="/access-agreement-ridb">
                                    RIDB API Access Agreement
                                </RidbLink>
                                .
                            </p>
                            <p>
                                There is no need to contact us before incorporating the
                                data into your system. In exchange, we encourage you to
                                provide a link to Recreation.gov and acknowledge credit,
                                such as
                                <code>&quot;data source: ridb.recreation.gov&quot;</code>.
                            </p>
                        </div>
                    </div>
                </div>
            </main>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
