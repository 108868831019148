/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { Button, Icons, TYPES } from 'sarsaparilla';
import { ICurrentUser } from '../shared/types/auth/currentUser';
import { Globals } from '../shared/globals';
import Auth, { AuthTargetedTypes } from '../shared/services/auth';
import { getCurrentUser } from '../reducers/currentUser';
import { getMachineKeys } from '../reducers/apiKey';
import { logout, reload } from '../actions/currentUser';
import { formatFullNameFromToken } from '../shared/utils/string';
import HeaderLink from '../shared/components/HeaderLink';
import { apiKeyDefaults } from '../shared/constants/auth';
import AnyFunction = Globals.AnyFunction;
import TargetedEvent = Globals.TargetedEvent;
import RidbLink from '../shared/components/RidbLink';
import redirect = Globals.redirect;
import { updateUserSearchTerm } from '../actions/userSearch';
import { fetchMachineKeys } from '../actions/apiKeys';
import { IMachineApiKey } from '../shared/types/auth/machineApiKey';
import '../scss/containers/_Header.scss';
import '../scss/containers/_AccountInfo.scss';

type AccountInfoProps = {
    history?: typeof TYPES.HISTORY;
    currentUser?: ICurrentUser;
    apiKeys: IMachineApiKey[];
    logout: AnyFunction;
    reload: AnyFunction;
    updateUserSearchTerm: AnyFunction;
    fetchMachineKeys: AnyFunction;
};
type PopoverStateValues = 'open' | 'close';

export class AccountInfo extends PureComponent<AccountInfoProps> {
    anchorRef = null;

    state = {
        showingPopover: false,
    };

    constructor(props: AccountInfoProps) {
        super(props);
    }

    async componentDidMount() {
        const { reload, fetchMachineKeys } = this.props;
        const user = Auth.target(AuthTargetedTypes.USER);
        if (Auth.isLoggedIn()) {
            reload(user);
            await fetchMachineKeys();
        }
        document.addEventListener('click', this.handleClickOutsidePopover);
        document.addEventListener('keyup', this.handleEscapeKey);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutsidePopover);
        document.removeEventListener('keyup', this.handleEscapeKey);
    }

    logout = () => {
        const { logout, history, updateUserSearchTerm } = this.props;
        logout().then(() => {
            Auth.clearData();
            redirect(history, '/');
            updateUserSearchTerm('');
        });
    };

    togglePopover = (popover: PopoverStateValues = void 0) => {
        let setTo = void 0;
        if (popover) {
            setTo = popover === 'open';
        } else {
            const { showingPopover } = this.state;
            setTo = !showingPopover;
        }
        this.setState({ showingPopover: setTo });
    };

    eventToggle = (e, popover: PopoverStateValues = void 0) => {
        e.preventDefault();
        this.togglePopover(popover);
    };

    keyDownToggle = (e, popover: PopoverStateValues = void 0) => {
        const { code, which } = e;
        if (code === 'Space' || code === 'Enter' || which === '32' || which === '13') {
            this.eventToggle(e, popover);
        }
    };

    handleClickInsideAnchor = (e: TargetedEvent) => this.eventToggle(e);

    handleClickInsidePopover = (e: TargetedEvent) => this.eventToggle(e, 'close');

    handleKeyDownInsideAnchor = (e: TargetedEvent) => this.keyDownToggle(e);

    handleKeyDownInsidePopover = () => this.keyDownToggle('close');

    handleClickOutsidePopover = (e: TargetedEvent) => {
        if (
            this.state.showingPopover &&
            e.target !== this.anchorRef &&
            e.target.parentNode !== this.anchorRef
        ) {
            this.togglePopover('close');
        }
    };

    handleEscapeKey = (e: { keyCode?: number; key: string }) => {
        const { key, keyCode } = e;
        const shouldDismiss =
            'key' in e ? key === 'Escape' || key === 'Esc' : keyCode === 27;

        if (shouldDismiss && this.state.showingPopover) {
            this.togglePopover('close');
        }
    };

    render() {
        const machineKeys = Auth.target(AuthTargetedTypes.API_KEYS);
        const {
            currentUser: { account, apiKey: { adminAccess } = apiKeyDefaults },
            apiKeys,
        } = this.props;
        const loggedIn = Auth.isLoggedIn();

        if (!loggedIn) {
            return (
                <div>
                    <HeaderLink text="Sign in" to="/login" />
                </div>
            );
        }
        return (
            <div
                tabIndex={0}
                role="button"
                className="logged-in"
                onKeyDown={this.handleKeyDownInsideAnchor}
                onClick={this.handleClickInsideAnchor}
            >
                <div
                    className="anchor"
                    ref={(ref) => {
                        this.anchorRef = ref;
                    }}
                >
                    <Icons.IconAccountCircle />
                    <div>{formatFullNameFromToken(account)}</div>
                </div>
                {this.state.showingPopover && (
                    <div
                        tabIndex={0}
                        role="button"
                        className="popover"
                        onKeyDown={this.handleKeyDownInsidePopover}
                        onClick={this.handleClickInsidePopover}
                    >
                        <span className="rec-sr-only">Account Info</span>
                        <ul>
                            <li>
                                <RidbLink to="/profile">Profile</RidbLink>
                            </li>
                            {(!!apiKeys?.length || !!machineKeys?.length) && (
                                <li>
                                    <RidbLink className="no-wrap" to="/machine-tokens">
                                        Machine API Tokens
                                    </RidbLink>
                                </li>
                            )}
                            {adminAccess && (
                                <li>
                                    <RidbLink className="no-wrap" to="/admin">
                                        Admin Users
                                    </RidbLink>
                                </li>
                            )}
                            <li>
                                <Button appearance="link" onClick={this.logout}>
                                    Logout
                                </Button>
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
    apiKeys: getMachineKeys(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logout,
            reload,
            updateUserSearchTerm,
            fetchMachineKeys,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter<any, any>(AccountInfo));
