/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import memoize from 'memoize-one';
import { TYPES } from 'sarsaparilla';
import { fetchOrganizations } from '../../actions/organization';
import { saveSearch } from '../../actions/search';
import { getState } from '../../reducers';
import { getAssets, getAssetCount } from '../../reducers/assets';
import { getCurrentUser } from '../../reducers/currentUser';
import { getOrganization, getOrganizations } from '../../reducers/organizations';
import { getSearch } from '../../reducers/search';
import { ICurrentUser } from '../../shared/types/auth/currentUser';
import { ISearchQuery } from '../../shared/types/search';
import { IAsset } from '../../shared/types/asset/asset';
import { IOrganization } from '../../shared/types/asset/organization';
import { Globals } from '../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import { StateStatus } from '../../shared/utils/async';
import { flattenOrg, flattenOrgs, orgsToOptions } from '../../shared/utils/organization';
import {
    StewardSearchForm,
    stewardSearchFormDefaultProps,
} from '../../components/StewardAsset/StewardSearchForm';
import Alert from '../../shared/components/Alert';
import { fetchChangeLogItems } from '../../actions/changeLog';
import mergeDefaults = Globals.mergeDefaults;
import redirect = Globals.redirect;
import { useMountEffect } from '../../shared/hooks/useMountEffect';
import '../../scss/containers/_StewardEntryPage.scss';

const stewardAssetEntryPageAlertConfig = {
    id: '1',
    level: 'info',
    title: 'Reservable & Non-Reservable Records',
    message: `Both reservable and non-reservable records are available for viewing in the RIDB UI and API but you
                              will only be able to edit non-reservable records here. To edit reservable records use the links provided that
                              will open the Hub.`,
};

type StewardAssetEntryPageProps = {
    history?: typeof TYPES.HISTORY;
    currentUser?: ICurrentUser;
    searchQuery?: ISearchQuery;
    assets?: IAsset[];
    assetCount?: number;
    organization?: IOrganization;
    organizations?: IOrganization[];
    states: Record<any, number>;
    fetchOrganizations: AnyFunction;
    saveSearch: AnyFunction;
};

function StewardAssetEntryPage(props: StewardAssetEntryPageProps) {
    const searchForms = { recarea: 'Rec Area', facility: 'Facility' };

    const getOrgOptions = memoize(
        (
            organizationsState,
            currentUserState,
            currentUser,
            organization,
            organizations
        ) => {
            if (
                organizationsState === StateStatus.READY &&
                currentUserState === StateStatus.READY
            ) {
                if (currentUser && currentUser.apiKey.orgId && organization) {
                    return orgsToOptions(flattenOrg(organization));
                }
                if (organizations) {
                    return orgsToOptions(flattenOrgs(organizations));
                }
            }

            return [];
        }
    );

    useMountEffect(() => {
        const {
            states: { organizations },
            fetchOrganizations,
        } = props;
        if (organizations === StateStatus.UNLOADED) {
            fetchOrganizations();
        }
    });

    const doSearch = (query: ISearchQuery) => {
        const assetType = query.assetTypes[0];
        const { saveSearch, history } = props;
        saveSearch(query).then(() =>
            redirect(history, `/data-steward/${assetType}/search`)
        );
    };

    const doInsert = (query: ISearchQuery) => {
        const assetType = query.assetTypes[0];
        const { history } = props;
        redirect(history, `/data-steward/${assetType}/new`);
    };

    const {
        states: { organizations: organizationsState, currentUser: currentUserState },
        currentUser,
        organization,
        organizations,
    } = mergeDefaults<StewardAssetEntryPageProps>(props, stewardSearchFormDefaultProps);
    const orgOptions = getOrgOptions(
        organizationsState,
        currentUserState,
        currentUser,
        organization,
        organizations
    );

    return (
        <div className="entry-page">
            <Alert alert={stewardAssetEntryPageAlertConfig} />
            <h1>Search</h1>
            <div className="search-form">
                {Object.keys(searchForms).map((key) => (
                    <StewardSearchForm
                        key={key}
                        id={key}
                        name={searchForms[key]}
                        orgOptions={orgOptions}
                        onSearch={doSearch}
                        onInsert={doInsert}
                    />
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
    searchQuery: getSearch(state),
    assets: getAssets(state),
    assetCount: getAssetCount(state),
    organization: getOrganization(state, getCurrentUser(state).apiKey.orgId),
    organizations: getOrganizations(state),
    states: getState(state, 'organizations', 'currentUser', 'changeLog'),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            saveSearch,
            fetchOrganizations,
            fetchChangeLogItems,
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(StewardAssetEntryPage)
);
