/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { alertDefaults } from '../shared/constants/alerts';
import { RIDB_ACTION_PREFIX } from '../shared/constants/actions';

const makeAlertActions = (prefix) => {
    const addAlertType = `${prefix}ADD_ALERT`;
    const removeAlertType = `${prefix}REMOVE_ALERT`;
    const clearAlertsType = `${prefix}CLEAR_ALERTS`;

    return {
        addAlert:
            ({ title, message = '', group = '', level = 'error', timeout = -1 }) =>
            (dispatch) => {
                const payload = {
                    ...alertDefaults,
                    id: uniqueId('alert-'),
                    title,
                    message,
                    group,
                    level,
                    timeout: null,
                };

                payload.onClose = () => {
                    if (payload.timeout) {
                        clearTimeout(payload.timeout);
                    }
                    dispatch({ type: removeAlertType, payload });
                };

                if (timeout > 0) {
                    payload.timeout = setTimeout(() => {
                        dispatch({ type: removeAlertType, payload });
                    }, timeout);
                }

                dispatch({ type: addAlertType, payload });
            },
        clearAlerts:
            (group = '') =>
            (dispatch) =>
                dispatch({ type: clearAlertsType, payload: group }),
    };
};

export const { addAlert, clearAlerts } = makeAlertActions(RIDB_ACTION_PREFIX);
