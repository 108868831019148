/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Autosuggest from 'react-autosuggest';
import { IAsset } from '../../../shared/types/asset/asset';
import { ISearchQuery } from '../../../shared/types/search';
import { Globals } from '../../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import { StateStatus } from '../../../shared/utils/async';
import { getState } from '../../../reducers';
import {
    fetchAssetsForAutoComplete,
    resetAssetsForAutoComplete,
} from '../../../actions/asset';
import { getAssetsForAutoComplete } from '../../../reducers/assetsForAutoComplete';
import '../../../scss/components/StewardAssetEditPage/_SuggestSearch.scss';

type SuggestSearchProps = {
    id: string;
    value: IAsset;
    placeholder: string;
    query: ISearchQuery;
    field: string;
    idField: string;
    onChange: AnyFunction;
    suggestions: any[];
    states: { [key: string]: number };
    onSearch: AnyFunction;
    onClearSuggestions: AnyFunction;
};

type SuggestSearchState = {
    searchText: string;
};

class SuggestSearch extends Component<SuggestSearchProps, SuggestSearchState> {
    constructor(props) {
        super(props);
        this.state = {
            searchText: get(props, 'value.name', ''),
        };
    }

    componentDidUpdate({ value: prevValue }) {
        const { value } = this.props;
        if (value && prevValue !== value) {
            this.setState({ searchText: value.name });
        }
    }

    onSuggestionsFetchRequested = ({ value: terms }) => {
        const {
            states: { assetsForAutoComplete },
            onSearch,
            query,
        } = this.props;
        if (assetsForAutoComplete !== StateStatus.LOADING) {
            onSearch({ ...query, terms });
        }
    };

    onSuggestionsClearRequested = () => {
        const { onClearSuggestions } = this.props;
        onClearSuggestions();
    };

    getSuggestionValue = ({ name }) => name;

    renderSuggestion = (suggestion, { isHighlighted }) => {
        const className = isHighlighted ? 'highlighted' : '';
        return <div className={className}>{this.getSuggestionValue(suggestion)}</div>;
    };

    onSuggestionSelected = (event, { suggestion }) => {
        const { field, idField, onChange } = this.props;
        onChange({ target: { value: suggestion, dataset: { field, idField } } });
    };

    render() {
        const { searchText: value = '' } = this.state;
        const { id, placeholder = '', field, suggestions } = this.props;
        const inputProps = {
            id,
            placeholder,
            value,
            onChange: ({ target: { value: searchText } }) =>
                this.setState({ searchText }),
            'data-field': field,
        };
        return (
            <Autosuggest
                suggestions={suggestions || []}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                onSuggestionSelected={this.onSuggestionSelected}
                inputProps={inputProps}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        suggestions: getAssetsForAutoComplete(state),
        states: getState(state, 'assetsForAutoComplete'),
    };
}

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            onSearch: fetchAssetsForAutoComplete,
            onClearSuggestions: resetAssetsForAutoComplete,
        },
        dispatch
    );

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SuggestSearch as any)
) as any;
