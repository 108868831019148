/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { RIDB_ACTION_PREFIX } from '../shared/constants/actions';

const makeAlertReducers = (prefix: string) => {
    const addAlertType = `${prefix}ADD_ALERT`;
    const removeAlertType = `${prefix}REMOVE_ALERT`;
    const clearAlertsType = `${prefix}CLEAR_ALERTS`;

    return {
        alerts: (state = [], { type, payload }) => {
            if (typeof type === 'undefined' || !type.startsWith(prefix)) {
                return state;
            }
            switch (type) {
                case addAlertType:
                    return [...state, payload];
                case removeAlertType:
                    return state.filter((item) => item !== payload);
                case clearAlertsType:
                    return state.filter(({ group, timeout }) => {
                        if (group === payload) {
                            if (timeout) {
                                clearTimeout(timeout);
                            }
                            return false;
                        }
                        return true;
                    });
                default:
                    return state;
            }
        },
    };
};

const makeAlertSelectors = (uiState) => {
    return {
        getAlerts: (state, group = '') => {
            if (!state[uiState] || !state[uiState].alerts) {
                return [];
            }

            return state[uiState].alerts.filter((alert) => alert.group === group);
        },
    };
};

export const { alerts } = makeAlertReducers(RIDB_ACTION_PREFIX);
export const selectors = makeAlertSelectors('portal');
