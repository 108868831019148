/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { keysToSnakeCase } from '../shared/utils/object';
import { makeAsyncAction, makeAsyncResetAction } from '../shared/utils/async';
import { FETCH_EVENTS, RESET_EVENTS } from '../shared/constants/actions';
import API from '../shared/api';
import Event from '../shared/utils/translators/event';

export const fetchEvents = makeAsyncAction(
    FETCH_EVENTS,
    (type, id, query) => () => API.fetchEvents(type, id, keysToSnakeCase(query)),
    undefined,
    {
        success: (dispatch, { type, payload }) => {
            const recData = payload.RECDATA.map((event) => Event.decode(event));
            return dispatch({ type, payload: recData });
        },
    }
);

export const resetEvents = makeAsyncResetAction(RESET_EVENTS);
