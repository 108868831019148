/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NotFoundPage } from 'sarsaparilla';

import { routes } from './routes';
import LandingPage from './containers/LandingPage';
import DownloadPage from './containers/DownloadPage';
import AccessAgreementPage from './containers/AccessAgreementPage';
import LoginPage from './containers/LoginPage';
import ProfilePage from './containers/ProfilePage/ProfilePage';
import PublishToCampingPage from './containers/PublishToCampingPage/PublishToCampingPage';
import MachineTokensPage from './containers/MachineTokensPage/MachineTokensPage';
import CustomProtectedRoute from './shared/components/CustomProtectedRoute';
import DocumentationPage from './containers/DocumentationPage';
import ChangeLogPage from './containers/ChangeLogPage';
import SearchPage from './containers/SearchPage';
import StandardsPage from './containers/StandardsPage';
import StewardAssetEntryPage from './containers/StewardAsset/StewardAssetEntryPage';
import StewardAssetDisplayPage from './containers/StewardAsset/StewardAssetDisplayPage';
import StewardAssetEditPage from './containers/StewardAsset/StewardAssetEditPage/StewardAssetEditPage';
import StewardAssetSearchPage from './containers/StewardAsset/StewardAssetSearchPage';

import StewardAssetNewPage from './containers/StewardAsset/StewardAssetNewPage';
import { Globals } from './shared/globals';
import configurePath = Globals.configurePath;
import UserAdminPage from './containers/UserAdminPage/UserAdminPage';

const components = {
    LandingPage,
    DownloadPage,
    AccessAgreementPage,
    LoginPage,
    ProfilePage,
    PublishToCampingPage,
    MachineTokensPage,
    DocumentationPage,
    ChangeLogPage,
    SearchPage,
    StandardsPage,
    StewardAssetEntryPage,
    StewardAssetDisplayPage,
    StewardAssetEditPage,
    StewardAssetSearchPage,
    StewardAssetNewPage,
    UserAdminPage,
};

export function makeSwitchRoutes() {
    const routeBase = process.env.ROUTE_BASE || '';
    return routes
        .map((route) => {
            const {
                componentName,
                requiresAdminAccess,
                requiresWriteAccess,
                exact = false,
                path: rawPath,
                requiresAuth,
            } = route;
            const path = configurePath(rawPath);
            const component = components[componentName];
            if (requiresAdminAccess || requiresWriteAccess || requiresAuth) {
                return (
                    <CustomProtectedRoute
                        key={path}
                        path={path}
                        component={component}
                        exact={exact}
                        requiresAdminAccess={requiresAdminAccess}
                        requiresWriteAccess={requiresWriteAccess}
                    />
                );
            }
            return <Route key={path} path={path} component={component} exact={exact} />;
        })
        .concat(
            <Route
                exact
                key="/landing-redirect-1"
                path={routeBase}
                render={() => <Redirect to={`${routeBase}/landing`} />}
            />,
            <Route
                exact
                key="/landing-redirect-2"
                path="/"
                render={() => <Redirect to={`${routeBase}/landing`} />}
            />,
            <Route path="*" key="notFound" component={NotFoundPage} />
        );
}
