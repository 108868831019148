/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import {
    FETCH_ASSETS_FOR_AUTOCOMPLETE,
    RIDB_ACTION_PREFIX,
} from '../shared/constants/actions';
import {
    asyncDefaults,
    makeAsyncReducer,
    makeAsyncResetReducer,
    mergeReducers,
} from '../shared/utils/async';

const fetchAssetsForAutoComplete = makeAsyncReducer(FETCH_ASSETS_FOR_AUTOCOMPLETE, {
    initialData: [],
});

const resetAssetsForAutoComplete = makeAsyncResetReducer(FETCH_ASSETS_FOR_AUTOCOMPLETE, {
    data: [],
    total_results: 0,
});

export default mergeReducers(
    asyncDefaults({
        data: [],
        total_results: 0,
    }),
    RIDB_ACTION_PREFIX,
    fetchAssetsForAutoComplete,
    resetAssetsForAutoComplete
);

export const getAssetsForAutoComplete = (state) =>
    get(state, 'portal.assetsForAutoComplete.data.data');
export const getAssetCountForAutoComplete = (state) =>
    get(state, 'portal.assetsForAutoComplete.data.total_results');
