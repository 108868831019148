/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import memoize from 'memoize-one';
import { BlockTabs, Button, ButtonGroup, Panel } from 'sarsaparilla';
import { fetchOrganizations } from '../../actions/organization';
import { getCurrentUser } from '../../reducers/currentUser';
import { getState } from '../../reducers';
import { getOrganization } from '../../reducers/organizations';
import {
    clearChanges,
    filterOldItems,
    getTypeFromChange,
    removeRefs,
    saveChanges,
} from '../../actions/changes';
import { ICurrentUser } from '../../shared/types/auth/currentUser';
import {
    IAsset,
    IAssetChange,
    StewardAssetInvalidItem,
    StewardAssetTabType,
} from '../../shared/types/asset/asset';
import { IOrganization } from '../../shared/types/asset/organization';
import { Globals } from '../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import { StateStatus } from '../../shared/utils/async';
import { flattenOrg, orgsToOptions } from '../../shared/utils/organization';
import { Organizations } from '../../shared/constants/asset/organization';
import { handleError } from '../../shared/constants/error';
import {
    determineAssetInvalidItems,
    getAssetTypeDetails,
} from '../../shared/utils/asset';
import LoadingWrapper from '../../shared/components/LoadingWrapper';
import AssetTab from '../../components/StewardAsset/StewardAssetEdit/tabs/AssetTab';
import ActivitiesTab from '../../components/StewardAsset/StewardAssetEdit/tabs/ActivitiesTab';
import AddressesTab from '../../components/StewardAsset/StewardAssetEdit/tabs/AddressesTab';
import LinkTab from '../../components/StewardAsset/StewardAssetEdit/tabs/LinkTab';
import ImagesTab from '../../components/StewardAsset/StewardAssetEdit/tabs/ImagesTab';
import redirect = Globals.redirect;
import { newAssetDefaults } from '../../shared/constants/asset/asset';
import SaveStatus from './StewardAssetEditPage/SaveStatus';
import '../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import { getSaveStatus } from '../../reducers/saveStatus';
import { saveClearErrors } from '../../actions/saveStatus';
import AnyObject = Globals.AnyObject;

type StewardAssetNewPageProps = {
    match?: any;
    history?: any;
    asset?: IAsset | any;
    organization?: IOrganization;
    states?: { [key: string]: number };
    currentUser?: ICurrentUser;
    fetchOrganizations: AnyFunction;
    clearChanges: AnyFunction;
    saveChanges: AnyFunction;
    saveClearErrors: AnyFunction;
    removeRefs: AnyFunction;
    changes?: IAssetChange[];
    saveStatus: any;
};

class StewardAssetNewPage extends Component<StewardAssetNewPageProps> {
    state = {
        asset: {
            ...newAssetDefaults,
        },
        allInvalidFields: [],
        isProgressOpen: false,
        lastAssetRef: '',
    };

    constructor(props: StewardAssetNewPageProps) {
        super(props);
    }

    componentDidMount() {
        const {
            states: { organizations },
            fetchOrganizations,
        } = this.props;
        if (organizations === StateStatus.UNLOADED) {
            fetchOrganizations();
        }
    }

    componentWillUnmount() {
        const { removeRefs } = this.props;
        removeRefs();
    }

    handleAssetUpdate = (assetChange: any, tabType: StewardAssetTabType) => {
        const { asset } = this.state;
        switch (tabType) {
            case 'assets':
                this.setState({ asset: assetChange });
                break;
            case 'activities':
            case 'addresses':
            case 'media':
            case 'links':
                const newState = { ...asset };
                delete newState[tabType];
                newState[tabType] = assetChange;
                this.setState({ asset: { ...newState } });
                break;
        }
    };

    getOrgOptions = memoize((organizationsState, currentUserState, organization) =>
        organizationsState === StateStatus.READY &&
        currentUserState === StateStatus.READY &&
        organization
            ? orgsToOptions(flattenOrg(organization))
            : []
    );

    doCancel = () => {
        const {
            match: {
                params: { assetType },
            },
            history,
        } = this.props;
        redirect(history, `/data-steward/${assetType}/search`);
    };

    doSave = () => {
        this.setState({ isProgressOpen: true });
        const {
            match: {
                params: { assetType },
            },
            saveChanges,
            history,
            saveClearErrors,
        } = this.props;
        const { changes } = this.props;
        const assetTabChanges = changes
            .filter((c) => c.type === 'recareas' || c.type === 'facilities')
            // .filter(filterOldItems(false, changes))
            .sort((a, b) => {
                const aRef = +a.ref.split('ref-')[1];
                const bRef = +b.ref.split('ref-')[1];
                return aRef > bRef ? -1 : aRef === bRef ? 0 : 1;
            });
        const mergedChanges: AnyObject = {};
        for (const aChange of assetTabChanges) {
            if (aChange && aChange.changes) {
                for (const key in aChange.changes) {
                    if (!mergedChanges[key]) {
                        mergedChanges[key] = aChange.changes[key];
                    }
                }
            }
        }

        // if they did not select an organization, set to the org specified on theur api key
        // if no org specified on api key set to default org ID FEDERAL
        const defaultOrg =
            this.props?.currentUser?.apiKey?.orgId || Organizations.FEDERAL;
        if (!mergedChanges.orgId) {
            mergedChanges.orgId = defaultOrg;
        }
        const assetTabChange = assetTabChanges[0];
        assetTabChange.changes = { ...mergedChanges };
        const finalChanges = changes
            .filter((c) => !(c.type === 'recareas' || c.type === 'facilities'))
            .map((change: IAssetChange) => {
                if (!change || !change.changes) {
                    return change;
                }
                const modifiedChange = { ...change };
                if (modifiedChange && modifiedChange.changes) {
                    modifiedChange.changes.orgId =
                        modifiedChange.changes.orgId || Organizations.FEDERAL;
                }
                return modifiedChange;
            })
            .concat([{ ...assetTabChange }])
            .concat(this.state.allInvalidFields.filter((f) => f.invalid));
        saveClearErrors();
        saveChanges(finalChanges, '').then((assetId) => {
            if (assetId) {
                redirect(history, `/data-steward/${assetType}/${assetId}/display`);
                this.setState({ asset: newAssetDefaults });
            }
        });
    };

    handleCloseModal = () => {
        this.setState({ isProgressOpen: false });
    };

    handleInvalidItems = (invalidItems: StewardAssetInvalidItem[]) => {
        const { allInvalidFields } = this.state;
        const finalItems = determineAssetInvalidItems(
            [...allInvalidFields],
            invalidItems
        );
        this.setState({ allInvalidFields: finalItems });
    };

    handleLastAssetRef = (ref: number) => {
        const formattedRef = `ref-${ref}`;
        if (this.state.lastAssetRef !== formattedRef) {
            this.setState({ lastAssetRef: formattedRef });
        }
    };

    render() {
        const {
            match: {
                params: { assetType },
            },
            states: { organizations: organizationsState, currentUser: currentUserState },
            organization,
        } = this.props;
        const orgOptions = this.getOrgOptions(
            organizationsState,
            currentUserState,
            organization
        );
        const details = getAssetTypeDetails(assetType);
        const loading = organizationsState !== StateStatus.READY || !orgOptions;

        const { asset, isProgressOpen, lastAssetRef } = this.state;
        return (
            <div className="steward-asset-edit">
                {isProgressOpen && <SaveStatus onClose={this.handleCloseModal} />}
                <BlockTabs indentTabs>
                    <Panel label={details.name} className="asset-tab">
                        <LoadingWrapper loading={loading} size="lg">
                            <AssetTab
                                isNew
                                lastRef={lastAssetRef}
                                onLastAssetRef={this.handleLastAssetRef}
                                assetDetails={details}
                                value={asset}
                                orgOptions={orgOptions}
                                onAssetUpdate={this.handleAssetUpdate}
                                onInvalidItemsChange={this.handleInvalidItems}
                            />
                        </LoadingWrapper>
                    </Panel>
                    <Panel label="Activities" className="activities-tab">
                        <LoadingWrapper loading={loading} size="lg">
                            <ActivitiesTab
                                isNew
                                activityType={details.activities}
                                value={asset}
                                onAssetUpdate={this.handleAssetUpdate}
                            />
                        </LoadingWrapper>
                    </Panel>
                    <Panel label="Addresses" className="addresses-tab">
                        <LoadingWrapper loading={loading} size="lg">
                            <AddressesTab
                                isLoading={loading}
                                addressType={details.addresses}
                                value={asset}
                                onAssetUpdate={this.handleAssetUpdate}
                            />
                        </LoadingWrapper>
                    </Panel>
                    <Panel label="Links" className="links-tab">
                        <LoadingWrapper loading={loading} size="lg">
                            <LinkTab
                                isLoading={loading}
                                linkType={details.links}
                                value={asset}
                                onInvalidItemsChange={this.handleInvalidItems}
                                onAssetUpdate={this.handleAssetUpdate}
                            />
                        </LoadingWrapper>
                    </Panel>
                    <Panel label="Images" className="images-tab">
                        <LoadingWrapper loading={loading} size="lg">
                            <ImagesTab
                                mediaType={details.media}
                                value={asset as any}
                                onInvalidItemsChange={this.handleInvalidItems}
                                onAssetUpdate={this.handleAssetUpdate}
                            />
                        </LoadingWrapper>
                    </Panel>
                    <Panel label="Events" className="events-tab">
                        <p>Available after the record has been saved.</p>
                    </Panel>
                    <Panel label="Site Maps" className="siteMaps-tab">
                        <p>Available after the record has been saved.</p>
                    </Panel>
                </BlockTabs>
                <div className="form-footer">
                    <ButtonGroup>
                        <Button appearance="tertiary" onClick={this.doCancel}>
                            Cancel
                        </Button>
                        <Button onClick={this.doSave}>Save Changes</Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
    asset: {},
    organization: getOrganization(state, getCurrentUser(state).apiKey.orgId),
    states: getState(state, 'organizations', 'currentUser'),
    saveStatus: getSaveStatus(state),
    changes: state.portal.changes,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchOrganizations,
            saveChanges,
            clearChanges,
            saveClearErrors,
            removeRefs,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(StewardAssetNewPage);
