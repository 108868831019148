/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { isEmpty, snakeCase as toSnakeCase } from 'lodash';
import { isValidEmail } from './string';
import { facilityAssetTypeDetails } from '../constants/asset/facility';
import { recAreaAssetTypeDetails } from '../constants/asset/recArea';
import {
    AssetAPICall,
    IAssetChange,
    IAssetTypeDetails,
    StewardAssetInvalidItem,
} from '../types/asset/asset';
import { APIVerbs } from '../api';
import { validateMediaImage } from './validation';
import { Globals } from '../globals';
import RecArea from './translators/recArea';
import Facility from './translators/facility';
import Link from './translators/link';
import Media from './translators/media';
import Event from './translators/event';
import { ItemTranslator } from './translators/itemTranslator';
import AnyFunction = Globals.AnyFunction;
import AnyObject = Globals.AnyObject;

type BuildCallPrimaryType = 'Rec Area' | 'Facility';
const getPrimaryType = (type: string): BuildCallPrimaryType =>
    type.indexOf('recarea') !== -1 ? 'Rec Area' : 'Facility';

type BuildCallSecondaryType =
    | 'Activities'
    | 'Link'
    | 'Address'
    | 'Activities'
    | 'Body'
    | 'Image'
    | 'Event';
const getSecondaryType = (type: string): BuildCallSecondaryType => {
    let secondaryType: BuildCallSecondaryType = 'Body';
    if (type.indexOf('activit') !== -1) {
        secondaryType = 'Activities';
    } else if (type.indexOf('address') !== -1) {
        secondaryType = 'Address';
    } else if (type.indexOf('link') !== -1) {
        secondaryType = 'Link';
    } else if (type.indexOf('media') !== -1) {
        secondaryType = 'Image';
    } else if (type.indexOf('event') !== -1) {
        secondaryType = 'Event';
    }
    return secondaryType;
};

const getEncodingFn = (
    primaryType: BuildCallPrimaryType,
    secondaryType: BuildCallSecondaryType
): AnyFunction => {
    const translator: ItemTranslator = primaryType === 'Rec Area' ? RecArea : Facility;
    switch (secondaryType) {
        case 'Body':
            return translator.encode;
        case 'Activities':
            return translator.encodeActivity;
        case 'Address':
            return translator.encodeAddress;
        case 'Image':
            return Media.encode;
        case 'Link':
            return Link.encode;
        case 'Event':
            return Event.encode;
    }
};

export const buildAssetAPICall = (
    change: IAssetChange,
    assetId: number
): AssetAPICall => {
    const { type, ref, _delete, ids: changeIds, changes } = change;
    const record: any = { ...changeIds, ...changes };
    const primaryType = getPrimaryType(type);
    const secondaryType = getSecondaryType(type);
    const encode = getEncodingFn(primaryType, secondaryType);
    const descriptionNoun = `${primaryType} ${secondaryType}`;
    const data = encode(record);
    const {
        eventID,
        recAreaID,
        facilityID,
        activityID,
        recAreaAddressID,
        facilityAddressID,
        entityLinkID,
    } = data;
    let verb: APIVerbs = 'PUT';
    let options = {};
    let url = '';

    switch (type) {
        case 'recarea':
        case 'recareas':
            if (_delete) {
                verb = 'DELETE';
                url = `/recareas/${recAreaID}`;
            } else if (!recAreaID) {
                verb = 'POST';
                url = `/recareas`;
            } else {
                url = `/recareas/${recAreaID}`;
            }
            break;
        case 'facility':
        case 'facilities':
            if (_delete) {
                verb = 'DELETE';
                url = `/facilities/${facilityID}`;
            } else if (!facilityID) {
                verb = 'POST';
                url = `/facilities`;
            } else {
                url = `/facilities/${facilityID}`;
            }
            break;
        case 'recareaactivity':
        case 'recareaactivities':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/recareas/${assetId}/activities/${activityID}`;
            } else {
                verb = 'POST';
                url = `/recareas/${assetId}/activities`;
            }
            break;
        case 'facilityactivity':
        case 'facilityactivities':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/facilities/${assetId}/activities/${activityID}`;
            } else {
                verb = 'POST';
                url = `/facilities/${assetId}/activities`;
            }
            break;
        case 'recareaaddress':
        case 'recareaaddresses':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/recareas/${assetId}/recareaaddresses/${recAreaAddressID}`;
            } else if (!recAreaAddressID) {
                verb = 'POST';
                url = `/recareas/${assetId}/recareaaddresses`;
            } else {
                url = `/recareas/${assetId}/recareaaddresses/${recAreaAddressID}`;
            }
            break;
        case 'facilityaddress':
        case 'facilityaddresses':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/facilities/${assetId}/facilityaddresses/${facilityAddressID}`;
            } else if (!facilityAddressID) {
                verb = 'POST';
                url = `/facilities/${assetId}/facilityaddresses`;
            } else {
                url = `/facilities/${assetId}/facilityaddresses/${facilityAddressID}`;
            }
            break;
        case 'recarealink':
        case 'recarealinks':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/recareas/${assetId}/links/${entityLinkID}`;
            } else if (!entityLinkID) {
                verb = 'POST';
                url = `/recareas/${assetId}/links`;
            } else {
                url = `/recareas/${assetId}/links/${entityLinkID}`;
            }
            break;
        case 'facilitylink':
        case 'facilitylinks':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/facilities/${assetId}/links/${entityLinkID}`;
            } else if (!entityLinkID) {
                verb = 'POST';
                url = `/facilities/${assetId}/links`;
            } else {
                url = `/facilities/${assetId}/links/${entityLinkID}`;
            }
            break;
        case 'recareamedia': {
            let info;
            [info, options] = data;
            const entityMediaID = isEmpty(options)
                ? info.entityMediaID
                : info.get('entityMediaID');
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/recareas/${assetId}/media/${entityMediaID}`;
            } else if (!entityMediaID) {
                const validationErrors = validateMediaImage(record);
                if (validationErrors !== null) {
                    throw validationErrors;
                }
                verb = 'POST';
                url = `/recareas/${assetId}/media`;
            } else {
                url = `/recareas/${assetId}/media/${entityMediaID}`;
            }
            break;
        }
        case 'facilitymedia': {
            let info;
            [info, options] = data;
            const entityMediaID = isEmpty(options)
                ? info.entityMediaID
                : info.get('entityMediaID');
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/facilities/${assetId}/media/${entityMediaID}`;
            } else if (!entityMediaID) {
                const validationErrors = validateMediaImage(record);
                if (validationErrors !== null) {
                    throw validationErrors;
                }
                verb = 'POST';
                url = `/facilities/${assetId}/media`;
            } else {
                url = `/facilities/${assetId}/media/${entityMediaID}`;
            }
            break;
        }
        case 'recareaevent':
        case 'recareaevents':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/recareas/${assetId}/events/${eventID}`;
            } else if (!eventID) {
                verb = 'POST';
                url = `/recareas/${assetId}/events`;
            } else {
                url = `/recareas/${assetId}/events/${eventID}`;
            }
            break;
        case 'facilityevent':
        case 'facilityevents':
            if (changes && changes._delete) {
                verb = 'DELETE';
                url = `/facilities/${assetId}/events/${eventID}`;
            } else if (!eventID) {
                verb = 'POST';
                url = `/facilities/${assetId}/events`;
            } else {
                url = `/facilities/${assetId}/events/${eventID}`;
            }
            break;
    }
    const descriptions = {
        POST: 'saving',
        DELETE: 'deleting',
        PUT: 'creating',
        default: 'creating',
    };
    const descriptionVerb = descriptions[verb] || descriptions.default;

    return {
        type,
        data: isMediaData(data) ? data[0] : data,
        verb,
        url,
        description: `${descriptionVerb} ${descriptionNoun}`,
        ref,
        options: isMediaData(data) ? data[1] : options,
    };
};

export function getAssetTypeDetails(type: string): IAssetTypeDetails {
    switch (type) {
        case 'facility':
        case 'facilities':
            return facilityAssetTypeDetails;
        default:
            return recAreaAssetTypeDetails;
    }
}

export const decodePostResponseId = (type: string, response) => {
    const {
        RecAreaID,
        FacilityID,
        ActivityID,
        RecAreaAddressID,
        FacilityAddressID,
        LinkID,
        MediaID,
        EventID,
    } = response;
    switch (type) {
        case 'recarea':
        case 'recareas':
            return RecAreaID;
        case 'facility':
        case 'facilities':
            return FacilityID;
        case 'recareaactivity':
        case 'recareaactivities':
        case 'facilityactivity':
        case 'facilityactivities':
            return ActivityID;
        case 'recareaaddress':
        case 'recareaaddresses':
            return RecAreaAddressID;
        case 'facilityaddress':
        case 'facilityaddresses':
            return FacilityAddressID;
        case 'recarealink':
        case 'recarealinks':
        case 'facilitylink':
        case 'facilitylinks':
            return LinkID;
        case 'recareamedia':
        case 'facilitymedia':
            return MediaID;
        case 'recareaevent':
        case 'recareaevents':
        case 'facilityevent':
        case 'facilityevents':
            return EventID;
        default:
            return null;
    }
};

export function emailField(value: string) {
    return !isValidEmail(value) ? ['Not a valid email address.'] : null;
}

export function typeToAsset(type: string) {
    switch (type?.toLowerCase()) {
        case 'campsites':
        case 'campsite':
            return 'campsites';
        case 'facilities':
        case 'facility':
            return 'facilities';
        case 'recareas':
        case 'recarea':
            return 'recareas';
        default:
            return 'asset';
    }
}

export function reduceAndRestructure(arr: any[], compareTo: AnyObject) {
    return arr.reduce(
        (val, index) => Object.assign(val, { [index]: compareTo[index] }),
        {}
    );
}

export const formatArrayQueryParams = (map: { [key: string]: any[] } = {}) => {
    const formatted = {};
    Object.keys(map).forEach((key) => {
        const value = map[key];
        if (value && value.length > 0) {
            const finalKey = `${toSnakeCase(key)}[]`;
            formatted[finalKey] = value.join(',');
        }
    });
    return formatted;
};

export function determineAssetInvalidItems(
    allCurrentInvalidFields: any[],
    invalidItems: StewardAssetInvalidItem[]
) {
    const passedItemsWithIds = invalidItems.filter((item) => item.id);
    let items = [...allCurrentInvalidFields];
    let currentItemsWithIds = items.filter((item) => item.id);
    passedItemsWithIds.forEach((idItem) => {
        const { id } = idItem;
        const filter = currentItemsWithIds.filter((i) => i.id === id);
        if (filter.length === 0) {
            currentItemsWithIds.push(idItem);
        } else if (filter.length === 1) {
            currentItemsWithIds = currentItemsWithIds
                .filter((c) => c.id !== id)
                .concat([idItem]);
        }
    });
    invalidItems
        .filter((i) => !i.id)
        .forEach((item) => {
            const { field, errorText, invalid } = item;
            const matchPattern = (invalidMatches: boolean) => (aField) =>
                aField.field === field &&
                aField.errorText === errorText &&
                (invalidMatches
                    ? aField.invalid === invalid
                    : aField.invalid !== invalid);

            if (items.filter(matchPattern(false)).length === 1) {
                items = items.filter(
                    (i) => i.field !== field && i.errorText !== errorText
                );
            } else if (items.filter(matchPattern(true)).length === 0) {
                items.push(item);
            }
        });
    return items.filter((i) => !i.id).concat(currentItemsWithIds);
}

const isMediaData = (data: any) => data && data.length === 2;
