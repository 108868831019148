/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/* eslint-disable no-confusing-arrow */

const getHost = (env, isAPI = false) => {
    return env === 'local'
        ? 'http://localhost:9040'
        : env === 'prod'
          ? `https://ridb.recreation.gov${isAPI ? '' : ''}` // /ridb-v2
          : `https://ridb.r1s-${env}.com${isAPI ? '' : ''}`; // /ridb-v2
};

const getRootHost = (env) =>
    env === 'local'
        ? 'http://localhost:9001' // ui-account PORT
        : env === 'prod'
          ? `https://recreation.gov`
          : `https://r1s-${env}.com`;

const envConfig = (env) => ({
    COPYRIGHT_YEAR: new Date().getFullYear(),
    ENCRYPTION_KEY: '9DbRdaOplABZ5mNs',
    HUB_EDIT_URL: 'https://manage.recreation.gov/internal/account/facility-info/camping',
    RIDB_API: `${getHost(env, true)}/api`,
    RIDB_ROOT: `${getHost(env, true)}`,
    RIDB_VERSION: '1.0',
    ROUTE_BASE: '', // /ridb-v2
    SITE_ROOT: `${getRootHost(env)}`,
    USE_DEFAULT_TITLE: true,
});

const getEncodedEnvConfig = (env, configOverride = {}) => {
    const encodedEnvConfig = {};
    const given = { ...envConfig(env), ...configOverride };
    for (const key in given) {
        if (given.hasOwnProperty(key)) {
            encodedEnvConfig[key] = JSON.stringify(given[key]);
        }
    }
    return encodedEnvConfig;
};

module.exports = { getHost, getRootHost, envConfig, getEncodedEnvConfig };
