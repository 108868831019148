/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    FlexCol,
    FlexContainer,
    FlexRow,
    HelmetWrapper,
    Panel,
    SkipNavContent,
    Tabs,
} from 'sarsaparilla';
import Documentation from '../components/Documentation';
import { getCurrentUser } from '../reducers/currentUser';
import { ICurrentUser } from '../shared/types/auth/currentUser';
import { Globals } from '../shared/globals';
import getAssetPath = Globals.getAssetPath;
import '../scss/swagger/_swagger.scss';

// specs is path relative to the dist
const specs = [
    {
        key: 'ridb',
        label: 'RIDB API',
        specs: `${getAssetPath('swagger/ridb.yaml')}`,
        display: () => true,
    },
    {
        key: 'data-steward',
        label: 'Data Steward API',
        specs: `${getAssetPath('swagger/dataSteward.yaml')}`,
        display: (currentUser: ICurrentUser) =>
            currentUser.apiKey.writeAccess || currentUser.apiKey.adminAccess,
    },
    {
        key: 'availability',
        label: 'Availability API',
        specs: `${getAssetPath('swagger/availability.yaml')}`,
        display: (currentUser: ICurrentUser) =>
            currentUser.apiKey.tier1Access || currentUser.apiKey.adminAccess,
    },
    {
        key: 'data-standard',
        label: 'Data Standard API',
        specs: `${getAssetPath('swagger/dataStandard.yaml')}`,
        display: (currentUser: ICurrentUser) =>
            currentUser.apiKey.writeAccess || currentUser.apiKey.adminAccess,
    },
];

type DocumentationPageProps = {
    currentUser: ICurrentUser;
    route: { swaggerSpecs: string };
};

export function DocumentationPage(props: Partial<DocumentationPageProps> = {}) {
    const { currentUser } = props;
    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB API Documentation" />
            <main role="main">
                <FlexContainer>
                    <FlexRow>
                        <FlexCol>
                            <Tabs indentTabs>
                                {specs
                                    .filter(({ display }) => display(currentUser))
                                    .map(({ label, key, specs }) => (
                                        <Panel label={label} key={key}>
                                            <Documentation
                                                specs={specs}
                                                currentUser={currentUser}
                                            />
                                        </Panel>
                                    ))}
                            </Tabs>
                        </FlexCol>
                    </FlexRow>
                    <div id="swagger-docs" />
                </FlexContainer>
            </main>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DocumentationPage);
