/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

export const decodeMediaKeys = {
    EntityMediaID: 'id',
    MediaType: 'type',
    EntityID: 'entityId',
    EntityType: 'entityType',
    Title: 'title',
    Subtitle: 'subtitle',
    Description: 'description',
    EmbedCode: 'embedCode',
    Height: 'height',
    Width: 'width',
    URL: 'url',
    Credits: 'credits',
    IsPrimary: 'isPrimary',
    IsPreview: 'isPreview',
    IsGallery: 'isGallery',
};

export const encodeMediaKeys = {
    id: 'entityMediaID',
    type: 'mediaType',
    title: 'title',
    subtitle: 'subtitle',
    description: 'description',
    embedCode: 'embedcode',
    height: 'height',
    width: 'width',
    url: 'URL',
    image: 'image',
    credits: 'credits',
    isPrimary: 'isPrimary',
    isPreview: 'isPreview',
    isGallery: 'isGallery',
};

export const imageDefaults = {
    EntityMediaID: '',
    MediaType: '',
    EntityID: '',
    EntityType: '',
    Title: '',
    Subtitle: '',
    Description: '',
    EmbedCode: '',
    Height: 0,
    Width: 0,
    URL: '',
    Credits: '',
    IsPrimary: false,
    IsPreview: false,
    IsGallery: false,
};
