/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import Auth, { AuthTargetedTypes } from '../services/auth';

// a check() for the requireCurrentUser HOC to ensure the user has writeAccess
const hasWriteAccess = (user) => user.apiKey.writeAccess;

// a check() for the requireCurrentUser HOC to ensure the user has adminAccess
const hasAdminAccess = (user) => user.apiKey.adminAccess;

type ProtectedRouteProps = {
    requiresWriteAccess: boolean;
    requiresAdminAccess: boolean;
} & any;

export default function CustomProtectedRoute(props: ProtectedRouteProps) {
    const { requiresWriteAccess, requiresAdminAccess } = props;
    const isLoggedIn = Auth.isLoggedIn();
    const user = Auth.target(AuthTargetedTypes.USER);

    const hasCorrectWritePermissions = requiresWriteAccess
        ? isLoggedIn && hasWriteAccess(user)
        : isLoggedIn;

    const hasCorrectAdminPermissions = requiresAdminAccess
        ? isLoggedIn && hasAdminAccess(user)
        : isLoggedIn;

    if (isLoggedIn && hasCorrectWritePermissions && hasCorrectAdminPermissions) {
        return <Route {...props} />;
    }

    return <Route path={props.path} render={() => <Redirect to="/" />} />;
}
