/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { FlexCol, FlexContainer, FlexRow } from 'sarsaparilla';
import { Globals } from '../shared/globals';
import contactUsUrl = Globals.contactUsUrl;
import RidbLink from '../shared/components/RidbLink';
import '../scss/components/_Footer.scss';

export function Footer() {
    return (
        <footer role="contentinfo" className="footer">
            <FlexContainer>
                <FlexRow>
                    <FlexCol sm="variable">
                        <span>
                            © {process.env['COPYRIGHT_YEAR']} {process.env['SITE_NAME']}.
                            All Rights Reserved
                        </span>
                    </FlexCol>
                    <FlexCol>
                        <a className="contact-us" href={contactUsUrl}>
                            Contact Us
                        </a>
                        &nbsp; &nbsp;
                        <RidbLink to="/changelog">Changelog</RidbLink>
                    </FlexCol>
                </FlexRow>
            </FlexContainer>
        </footer>
    );
}

export default Footer;
