/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { FETCH_ORGANIZATIONS, RIDB_ACTION_PREFIX } from '../shared/constants/actions';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';

const fetchOrganizations = makeAsyncReducer(FETCH_ORGANIZATIONS);

export default mergeReducers(asyncDefaults([]), RIDB_ACTION_PREFIX, fetchOrganizations);

export const getOrganizations = (state) => state.portal.organizations.data;

export const getOrganization = (state, orgId: string) => {
    const { loaded, error, data } = state.portal.organizations;
    if (!loaded || !!error) {
        return null;
    }

    const findOrg = (org: { id: string; children: any[] }) => {
        if (org.id === orgId) {
            return org;
        }

        if (org.children) {
            let result = null;
            for (const child of org.children) {
                if (!result) result = findOrg(child);
            }
            return result;
        }
        return null;
    };

    // Handle Root level data stewards
    if (orgId === '') {
        return {
            id: '',
            name: 'Root',
            parent_id: '',
            path: [],
            children: data,
        };
    }
    let result = null;
    for (let idx = 0; result == null && idx < data.length; idx += 1) {
        result = findOrg(data[idx]);
    }
    return result;
};
