/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { combineReducers } from 'redux';
import { sarsaparillaReducers } from 'sarsaparilla';
import portalReducers from './reducers';

const rootReducer = combineReducers({
    portal: portalReducers,
    ...sarsaparillaReducers,
});

export default rootReducer;
