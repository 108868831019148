/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'sarsaparilla';
import '../scss/containers/_AccessAgreementPage.scss';

function AccessAgreementPage() {
    const history = useHistory();

    const handleScroll = () => {
        const element = document.getElementById('ridb-header');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className="access-agreement">
            <p className="title">
                <Button className="top" onClick={() => history.goBack()}>
                    Go Back
                </Button>
                <strong>RIDB API ACCESS AGREEMENT</strong>
            </p>
            <button
                type="button"
                onClick={handleScroll}
                style={{
                    position: 'fixed',
                    height: '4rem',
                    minWidth: '4rem',
                    padding: '0 2.4rem',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    bottom: '40px',
                    right: '40px',
                    backgroundColor: '#345d96',
                    color: '#fff',
                    textAlign: 'center',
                    border: '1px',
                    borderRadius: '4px',
                }}
            >
                Scroll to top
            </button>
            <p>
                Thank you for your interest in using the Recreation Information Database
                (RIDB) application programming interface (the &ldquo;API&rdquo;) which is
                being provided pursuant to the API Vendor&rsquo;s prime contract with the
                U.S. Forest Service for operation and maintenance of the recreation.gov
                site.&nbsp; By registering to use the API, you are agreeing to comply with
                all terms and conditions of this API Access Agreement (this
                &ldquo;Agreement&rdquo;).&nbsp; Please read this Agreement carefully and
                only register to use the API if you agree to be bound by all of its terms
                and conditions.
            </p>
            <p>
                If you do not agree to any aspect of this Agreement, then you may not use
                the API.&nbsp; In addition, you may not enter into this Agreement and may
                not use the API if (i) you are not of legal age to form a binding contract
                with API Vendor, (ii) you are restricted from using the API under the
                applicable laws of the United States, the country in which you are
                resident or the country from which you intend to use the API; (iii) you
                are suspended, debarred, proposed for debarment, or otherwise excluded
                from participation in procurement or non-procurement programs of the U.S.
                government; or (iv) you are designated on a sanctions list maintained by
                the U.S. Department of the Treasury Office of Foreign Assets Control; or
                located in, a national or resident of, headquartered in or organized under
                the laws of, Cuba, Iran, North Korea, Syria, or the Crimea region of
                Ukraine; or controlled or majority-owned by the government of such
                countries/regions; or acting on behalf of, controlled or majority-owned by
                any one or more of any such persons or entities.
            </p>
            <p>
                If the individual accepting this Agreement is doing so on behalf of a
                legal entity, then such individual hereby represents and warrants that
                he/she is authorized by such legal entity to bind it to this Agreement,
                and all references to &ldquo;you&rdquo; or &ldquo;your&rdquo; or the like
                in this Agreement shall be deemed to refer to that entity.
            </p>
            <ol>
                <li>
                    <u>Definitions</u>. The following capitalized terms have the meanings
                    set forth below when used in this Agreement:
                    <ol>
                        <li>
                            &ldquo;API Documentation&rdquo; means any and all reference
                            materials, integration instructions, sample code and other
                            documentation and other materials that may be made available
                            by API Vendor relating to the API, as the same may be amended
                            or revised from time to time by API Vendor in its sole
                            discretion.
                        </li>
                        <li>
                            &ldquo;API Key&rdquo; means the unique identifier issued by
                            API Vendor to you that must be sent as part of each Call.
                        </li>
                        <li>
                            &ldquo;API Vendor&rdquo; means Booz Allen Hamilton Inc., who
                            is providing operation and maintenance of the recreation.gov
                            site pursuant to a prime contract with the U.S. Department of
                            Agriculture, Forest Service (the &ldquo;Prime
                            Contract&rdquo;).
                        </li>
                        <li>
                            &ldquo;Call&rdquo; means any request for a result from rec.gov
                            enabled by API Vendor to be sent through the API.
                        </li>
                        <li>
                            &ldquo;Response&rdquo; means data sent through the API in
                            response to a Call.
                        </li>
                    </ol>
                </li>
                <li>
                    <u>API</u>.
                    <ol>
                        <li>
                            <u>Use</u>. Subject to all terms and conditions of this
                            Agreement, you may, on a non-exclusive, non-transferable and
                            revocable basis: (i) send Calls and receive Responses through
                            the API through use of the API Key, in each case solely as
                            prescribed by the API Documentation; and (ii) internally use
                            the API Documentation for purposes of sending Calls to, and
                            receiving Responses from, the API. As further detailed below,
                            you acknowledge that the API is made available on an &ldquo;AS
                            IS&rdquo; basis and may be subject to errors and
                            unavailability from time to time.
                        </li>
                        <li>
                            <u>No Other Rights</u>. You have no right or license to, and
                            shall not: (i) copy, distribute, rent, lease, lend,
                            sublicense, transfer or make derivative works of the API, the
                            API Documentation. the API Key (collectively, the &ldquo;API
                            Vendor Materials&rdquo;) or use any of the foregoing on a
                            service bureau basis; (ii) provide your API Key or access to
                            the API to any third party; (iii) decompile, reverse engineer,
                            or disassemble the API Vendor Materials or otherwise attempt
                            to discover the source code of the API Vendor Materials; (iv)
                            use the API Vendor Materials to create or make available an
                            application programming interface or other product or service
                            similar to, or that would otherwise be a substitute for, the
                            API; (v) modify, remove, or obscure any copyright, trademark,
                            patent or other notices or legends that may appear on the API
                            Vendor Materials or during the use and operation thereof; (vi)
                            work around any technical limitations in the API Vendor
                            Materials or access any aspect of the API Vendor Materials not
                            intentionally made available by API Vendor; or (vii) engage in
                            any activity with the API Vendor Materials that interferes
                            with, disrupts, damages or accesses in an unauthorized manner
                            the servers, networks or other properties or services of API
                            Vendor or any third party.
                        </li>
                        <li>
                            <u>Intellectual Property</u>. As between you and API Vendor,
                            all right, title and interest in and to the API Vendor
                            Materials and all derivative works thereof shall at all times
                            remain owned by API Vendor or its licensors, and no title,
                            ownership or other right to any intellectual property of API
                            Vendor is transferred to you.&nbsp; You shall not use the API
                            Vendor Materials or any Confidential Information (as defined
                            below) to: (a) investigate, threaten or assert any claim of
                            infringement or misappropriation against API Vendor or any of
                            its affiliates, licensees or customers (direct or indirect);
                            or (b) prepare, file, prosecute or enforce any patents or
                            patent applications based upon or using API Vendor Materials
                            or any Confidential Information.&nbsp; If, notwithstanding the
                            foregoing, you or any of your affiliates prepares, files,
                            prosecutes or enforces any patents or patent applications in
                            breach of the foregoing, then API Vendor shall have (and you
                            hereby grant to API Vendor) a paid-up, royalty-free, worldwide
                            license under such patents to make, have made, use, sell,
                            offer for sale, import, otherwise exploit and sublicense;
                            provided, however, that this shall not be construed as
                            limiting any other rights or remedies available to API Vendor
                            for your breach of this Section 2.c.
                        </li>
                        <li>
                            <u>Feedback</u>. You are not required to provide to API Vendor
                            any ideas, concepts, suggestions or feedback regarding the API
                            Vendor Materials (&ldquo;Feedback&rdquo;). To the extent you
                            do provide any Feedback, you acknowledge and agree that API
                            Vendor may freely use, reproduce, modify, distribute, make,
                            have made, sell, offer for sale, import and otherwise exploit
                            in any manner such Feedback on a perpetual, irrevocable and
                            worldwide basis without payment of any royalties or other
                            consideration to you.
                        </li>
                        <li>
                            <u>Monitoring</u>. API Vendor reserves the right, both for
                            itself and its designees, to monitor and analyze all usage of
                            the API, including the contents of Calls.&nbsp; You hereby
                            consent to, and agree not to interfere with, any such
                            monitoring or analysis.
                        </li>
                        <li>
                            <u>Responses</u>. You acknowledge that the content of
                            Responses is provided by the United States Government and is
                            not independently verified by API Vendor.&nbsp; As such, you
                            agree that API Vendor shall have no liability to you in
                            connection with the accuracy or reliability of the Responses
                            or any actions or omissions you undertake in reliance on any
                            Responses.&nbsp; You agree not to misrepresent the source of
                            any Responses.
                        </li>
                        <li>
                            <u>Modifications</u>. API Vendor may modify, suspend or
                            discontinue all or any aspect of the API or API Documentation,
                            including the formatting and content of Calls and the types of
                            information available in Responses, at any time and without
                            prior notice.
                        </li>
                    </ol>
                </li>
                <li>
                    <u>Requirements and Restrictions</u>.
                    <ol>
                        <li>
                            <u>Responsibility</u>. You assume full responsibility for all
                            acts and omissions (including breach of this Agreement)
                            accomplished in connection with the API Key, whether such acts
                            or omissions are undertaken by you or a third party.
                        </li>
                        <li>
                            <u>Transaction Limit</u>. API Vendor reserves the right,
                            without prior notice and in its sole discretion, to impose
                            limits on the number or rate of Calls, or to impose fees for
                            high-frequency Calls exceeding a specified volume over a given
                            period of time.&nbsp; You agree not to attempt to circumvent
                            any such limitations which may be imposed.
                        </li>
                        <li>
                            <u>Prohibited Activities</u>. You agree not to use the API or
                            the content or information delivered as part of the
                            Responses:&nbsp; (i) in connection with any violation of any
                            law, statute, ordinance or regulation (including without
                            limitation those governing export control, consumer
                            protection, unfair competition, anti-discrimination or false
                            advertising); (ii) in connection with any fraudulent,
                            deceptive, unethical or misleading conduct; (iii) to introduce
                            any viruses, worms, defects, Trojan horses, malware, or any
                            other harmful or malicious software code into any system of
                            API Vendor or any third party; (iv) in connection with any
                            activities where the use or failure of the APIs could lead to
                            death, personal injury, or environmental damage; (v) in a
                            manner that may interfere with the functionality or
                            performance of the API; (vi) in connection with any content or
                            material that (1) consists of or promotes discrimination,
                            illegal activities, hate speech, graphic violence, terrorism,
                            unlawful firearms, tobacco, gambling, illegal drugs,
                            pornography, prostitution, profanity, obscenity or child
                            endangerment or (2) is threatening, racist, bullying or
                            harassing, or seeks to capitalize on any natural disaster,
                            war, conflict, unrest, death or other tragic event; or (vii)
                            in connection with any activity that we deem, in our sole
                            discretion, may be associated with an unreasonable level of
                            risk, may create liability for us or a third party, may cause
                            us to lose the services of any of our third party service
                            providers, may cause us to be in breach of a contract between
                            us and a third party, or may not reflect positively on API
                            Vendor.
                        </li>
                        <li>
                            <u>No Endorsement</u>. You shall not make any statement
                            indicating or implying that API Vendor or the United States
                            Government endorses any website, online service or other
                            products or service in connection with which you use the API.
                            You are encouraged to provide a link to recreation.gov or
                            otherwise identify the data source, ridb.recreation.gov, when
                            incorporating the data into your own system.
                        </li>
                        <li>
                            <u>Information</u>. You represent and warrant that all
                            information you provide at any time to API Vendor in
                            connection with your registration to use the API shall be
                            accurate and complete, and that you shall keep all such
                            information current.
                        </li>
                    </ol>
                </li>
                <li>
                    <u>Confidentiality</u>. &ldquo;Confidential Information&rdquo; means
                    the API Documentation, API Key, and all other information which is
                    designated as, or which you reasonably should understand to be,
                    confidential or proprietary to API Vendor. With respect to any
                    Confidential Information that is disclosed to you or that you
                    otherwise receive:
                    <ol>
                        <li>
                            you will: (i) protect and not disclose to third parties any
                            Confidential Information; (ii) restrict dissemination of
                            Confidential Information to your employees and contractors who
                            have a reasonable need-to-know in connection with your
                            authorized use of the API and who have signed an agreement
                            protecting the Confidential Information in a manner
                            substantially similar to the terms of this Section 4; and
                            (iii) not use any Confidential Information for any purpose
                            other than as expressly authorized hereunder or as may
                            otherwise be expressly authorized in writing by an authorized
                            representative of API Vendor;
                        </li>
                        <li>
                            you shall protect the Confidential Information by exercising
                            the same degree of care which you use to protect your own
                            confidential information of a similar nature, but in no event
                            less than reasonable care;
                        </li>
                        <li>
                            you agree that any violation or threatened violation of this
                            Agreement may cause irreparable injury to API Vendor,
                            entitling API Vendor to obtain injunctive or other equitable
                            relief in addition to all legal remedies; and
                        </li>
                        <li>
                            You agree that, upon the expiration or termination of this
                            Agreement, you will permanently destroy all Confidential
                            Information in your possession or control.
                        </li>
                    </ol>
                </li>
                <li>
                    <u>Non-Exclusivity</u>. You acknowledge that your right to use the API
                    hereunder is non-exclusive, and that API Vendor reserves the right to
                    deal in any manner with any third party.
                </li>
                <li>
                    <u>No Agency</u>. Nothing herein contained in this Agreement shall be
                    deemed to establish or otherwise create a relationship of principal
                    and agent, partners, fiduciaries, or joint venturers between API
                    Vendor and you. Neither party has any right or authority to assume or
                    create any obligation of any kind, whether express or implied, on
                    behalf of the other party.
                </li>
                <li>
                    <u>No Obligation to Support</u>. API Vendor has no obligation to
                    provide any technical support or assistance to you, including without
                    limitation assisting you with formatting or sending Calls or
                    maintaining, correcting, supporting or updating the API or API
                    Documentation.
                </li>
                <li>
                    <u>No Warranties; Assumption of Risk</u>. THE API, API DOCUMENTATION,
                    API KEY AND ANY RESPONSES OR OTHER INFORMATION PROVIDED TO YOU IN
                    CONNECTION WITH THIS AGREEMENT IS MADE AVAILABLE OR PROVIDED ON AN
                    &ldquo;AS IS&rdquo; BASIS AND, TO THE FULLEST EXTENT PERMITTED BY LAW,
                    API VENDOR AND ITS LICENSORS, SUPPLIERS AND SERVICE PROVIDERS DISCLAIM
                    ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION
                    WARRANTIES OF NONINFRINGEMENT, MERCHANTABILITY AND FITNESS FOR ANY
                    PARTICULAR PURPOSE.&nbsp; API VENDOR AND ITS LICENSORS, SUPPLIERS AND
                    SERVICE PROVIDERS DO NOT WARRANT THAT THE API OR RESPONSES SHALL BE
                    ACCURATE OR CURRENT, SHALL MEET YOUR REQUIREMENTS, THAT ACCESS TO OR
                    USE OF THE FOREGOING SHALL BE UNINTERRUPTED OR ERROR-FREE OR THAT
                    DEFECTS WILL BE CORRECTED.&nbsp; API VENDOR AND ITS LICENSORS,
                    SUPPLIERS AND SERVICE PROVIDERS DO NOT WARRANT THE RESULTS OF USE OF
                    THE API OR RESPONSES.&nbsp;&nbsp; YOU HEREBY ASSUME THE ENTIRE RISK OF
                    USE OF OR RELIANCE ON THE API, API DOCUMENTATION, API KEY AND ANY
                    RESPONSES OR OTHER INFORMATION PROVIDED TO YOU IN CONNECTION WITH THIS
                    AGREEMENT.
                </li>
                <li>
                    <u>Limitation of Liability</u>. UNDER NO CIRCUMSTANCES WILL API VENDOR
                    OR ITS AFFILIATES, LICENSORS, SUPPLIERS AND SERVICE PROVIDERS, OR
                    THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS, BE LIABLE
                    TO YOU FOR ANY CONSEQUENTIAL, SPECIAL, INDIRECT, INCIDENTAL, EXEMPLARY
                    OR PUNITIVE DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOST
                    PROFITS, LOST DATA, BUSINESS INTERRUPTION, LOSS OF BUSINESS REPUTATION
                    OR GOODWILL, OR THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
                    SERVICES, UNDER OR RELATING TO THIS AGREEMENT, WHETHER IN CONTRACT,
                    TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EVEN IF COMPANY HAS BEEN
                    ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.&nbsp; IN NO EVENT WILL THE
                    AGGREGATE LIABILITY OF API VENDOR OR ITS AFFILIATES LICENSORS,
                    SUPPLIERS AND SERVICE PROVIDERS, OR THEIR RESPECTIVE OFFICERS,
                    DIRECTORS, EMPLOYEES AND AGENTS, UNDER OR RELATING TO THIS AGREEMENT,
                    WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE, EXCEED
                    FIVE U.S. DOLLARS (USD $5).
                </li>
                <li>
                    <u>Indemnification</u>. You agree to indemnify and hold API Vendor and
                    its affiliates, and their respective officers, directors, employees
                    and agents, harmless from and against any and all claims, proceedings,
                    suits, damages, losses, liabilities, costs and expenses (including
                    reasonable attorneys&apos; fees) arising out of or relating to: (a)
                    any breach by you of any representation, warranty or obligation under
                    this Agreement; (b) any of your websites, online services or other
                    products or services; or (c) use of the API, your API Key or Responses
                    received in connection with your API Key (collectively,
                    &ldquo;Losses&rdquo;), except solely to the extent Losses directly
                    arise out of the gross negligence or willful misconduct of API Vendor.
                </li>
                <li>
                    <u>Internet Disclaimer</u>. You acknowledge and agree that the
                    Internet is a public network that API Vendor does not control.&nbsp;
                    API Vendor makes no representations or warranties whatsoever, and
                    shall have no liability whatsoever, with respect to the accuracy,
                    dependability, privacy, security, authenticity or completeness of data
                    transmitted over or obtained using the Internet, or any intrusion,
                    virus, disruption, loss of communication, loss or corruption of data,
                    or other error or event caused or permitted by or introduced through
                    your use of the Internet.&nbsp; You are solely responsible for
                    implementing adequate firewall, password and other security measures
                    to protect your systems, data and applications from unwanted
                    intrusion, whether over the Internet or by other means.
                </li>
                <li>
                    <u>Essential Element</u>. You agree that the disclaimers, limitations
                    on liability and indemnification provisions in Sections 8 through 11
                    constitute a fair allocation of risk between the parties and an
                    essential element of this Agreement, and acknowledge that API Vendor
                    would not make available the API without charge in the absence of your
                    agreement to such provisions.
                </li>
                <li>
                    <u>Term and Termination</u>. The term of this Agreement commences on
                    the date it is accepted by you and shall end when this Agreement is
                    terminated as provided for herein. Either party may terminate this
                    Agreement at any time, for any reason or for no reason.&nbsp; Without
                    limiting the generality of the foregoing, API Vendor may immediately
                    suspend your access to the API or terminate this Agreement upon your
                    breach of this Agreement, upon the institution by or against you of
                    any bankruptcy, reorganization, arrangement, insolvency or similar
                    proceedings under the laws of any jurisdiction, as may be required
                    pursuant to the Prime Contract or as otherwise instructed to do so by
                    the United States Government, or where API Vendor determines that your
                    continued use of the API would be inconsistent with the underlying
                    purpose of the Prime Contract.
                </li>
                <li>
                    <u>Rights and Obligations Upon Termination</u>. Upon termination of
                    this Agreement, you must immediately cease any use of the API.&nbsp;
                    All provisions of this Agreement other than Section 2.a. will survive
                    expiration or termination hereof.
                </li>
                <li>
                    <u>Notices</u>. Any notices given to you under this Agreement may be
                    sent to the e-mail address provided by you in connection with your
                    registration to use the API. Any notices given the API Vendor under
                    this Agreement shall be in writing and sent by email to
                    RIDBAPINotification@recreation.gov.
                </li>
                <li>
                    <u>General</u>.
                    <ol>
                        <li>
                            <u>Electronic Communications</u>. You consent to receive
                            communications from Company in an electronic form, and agree
                            that all terms and conditions, agreements, notices,
                            disclosures, and other communications that Company provides to
                            you electronically satisfy any legal requirement that such
                            communications would satisfy if it were be in a hardcopy
                            writing.
                        </li>
                        <li>
                            <u>Assignment</u>. You may not assign or otherwise transfer
                            this Agreement or any interest or right hereunder or delegate
                            the performance of any of your obligations hereunder, whether
                            by merger, operation of law or otherwise, without the prior
                            written consent of API Vendor.&nbsp; Any such attempted
                            assignment, transfer or delegation without API Vendor&rsquo;s
                            prior written consent will be deemed null and void and result
                            in the immediate termination of this Agreement without
                            necessity of notice. API Vendor may freely assign this
                            Agreement.&nbsp; Subject to the foregoing, this Agreement
                            shall bind and inure to the benefit of the parties and their
                            respective successors and assigns.
                        </li>
                        <li>
                            <u>Force Majeure</u>. API Vendor will not be liable for any
                            failure or delay in its performance under this Agreement due
                            to any cause beyond its reasonable control, which may include,
                            without limitation, acts of war, acts of God, earthquake,
                            flood, fire, embargo, riot, sabotage, labor shortage or
                            dispute, governmental act or failure of third party power or
                            telecommunications networks.
                        </li>
                        <li>
                            <u>Waivers</u>. No waiver by either party of any right or
                            remedy hereunder shall be valid unless the same shall be in
                            writing and signed by the party giving such waiver.&nbsp; No
                            waiver by either party with respect to any default,
                            misrepresentation, or breach of warranty or covenant hereunder
                            shall be deemed to extend to any prior or subsequent default,
                            misrepresentation, or breach of warranty or covenant hereunder
                            or affect in any way any rights arising by virtue of any prior
                            or subsequent such occurrence.
                        </li>
                        <li>
                            <u>Entire Agreement</u>. This Agreement constitutes the entire
                            agreement between API Vendor and you with respect to the use
                            of the API, API Key and API Documentation, and hereby
                            supersedes and terminates any prior agreements or
                            understandings relating to such subject matter.&nbsp; We may
                            make modifications, deletions and/or additions to this
                            Agreement (&ldquo;Changes&rdquo;) at any time.&nbsp; Changes
                            will be effective: (i) thirty (30) days after API Vendor sends
                            notice of the Changes to the e-mail address associated with
                            your registration to use the API; or (ii) when you opt-in or
                            otherwise expressly agree to the Changes or a version of this
                            Agreement incorporating the Changes, whichever comes
                            first.&nbsp; Except as expressly provided herein, no addendum,
                            waiver, consent, modification, amendment or change of the
                            terms of this Agreement shall bind either party unless in
                            writing and signed by duly authorized representatives of API
                            Vendor and you.
                        </li>
                        <li>
                            <u>Severability</u>. In the event that any provision of this
                            Agreement is held by a court of competent jurisdiction to be
                            unenforceable because it is invalid or in conflict with any
                            law of any relevant jurisdiction, the validity of the
                            remaining provision shall be not be affected, and the
                            unenforceable provision shall be replaced by an enforceable
                            substitute provision that most closely accomplishes the
                            original business purpose.
                        </li>
                        <li>
                            <u>Governing Law; Jurisdiction</u>. This Agreement shall be
                            governed by and construed in accordance with the laws of the
                            Commonwealth of Virginia, without giving effect to its
                            conflict of laws provisions.&nbsp; All disputes arising in
                            relation to this Agreement shall be subject to the exclusive
                            jurisdiction of the state and federal courts located in
                            Fairfax County, Virginia, and the parties hereby consent to
                            such exclusive jurisdiction.
                        </li>
                        <li>
                            <u>Export</u>. The API Documentation and other materials made
                            available to you by API Vendor may be subject to U.S. export
                            control laws and may be subject to export or import
                            regulations in other countries. You agree not to export,
                            reexport, or transfer, directly or indirectly, any API
                            Documentation or any such other materials, or any products
                            utilizing any of the foregoing, in violation of any applicable
                            export laws or regulation.
                        </li>
                        <li>
                            <u>Headings</u>. The section headings contained in this
                            Agreement are inserted for convenience only and shall not
                            affect in any way the meaning or interpretation of this
                            Agreement.
                        </li>
                    </ol>
                </li>
            </ol>
            <Button className="bottom" onClick={() => history.goBack()}>
                Go Back
            </Button>
        </div>
    );
}

export default AccessAgreementPage;
