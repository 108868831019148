/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { ICurrentUser } from '../types/auth/currentUser';

export const apiKeyDefaults = {
    externalId: '',
    apiKey: '',
    orgId: '',
    writeAccess: false,
    adminAccess: false,
    locked: false,
};

export const accountDefaults = {
    accountId: '',
    email: '',
    firstName: '',
    lastName: '',
    homePhone: '',
};

export const currentUserDefaults: ICurrentUser = {
    loggedIn: false,
    isDeveloper: false,
    isTier1Developer: false,
    isTier2Developer: false,
    account: accountDefaults,
    apiKey: apiKeyDefaults,
};

export enum AuthLocalStorageKeys {
    SESSION_TOKEN = 'developer-session-token',
    SESSION_USER = 'developer-session-user',
    SESSION_API_KEY = 'developer-session-apiKey',
}

export enum SarsaLocalStorageKeys {
    BUCKET_EXT = 'r1s-ld-user-bucket-ext-100',
    BUCKET_INT = 'r1s-ld-user-bucket-int-100',
}
