/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import RidbLink from './RidbLink';
import '../../scss/components/_HeaderLink.scss';

type HeaderLinkProps = {
    text: string;
    to: string;
    menuItemStartActive?: boolean;
};

function HeaderLink(props: HeaderLinkProps) {
    return (
        <div className="menu-item">
            <RidbLink
                activeclassname="active-header-link"
                menu-item={props.menuItemStartActive ? 'active' : 'undefined'}
                to={props.to}
            >
                {props.text}
            </RidbLink>
        </div>
    );
}

export default HeaderLink;
