/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import {
    asyncDefaults,
    makeAsyncReducer,
    makeAsyncSelectors,
    mergeReducers,
} from '../shared/utils/async';
import {
    RIDB_ACTION_PREFIX,
    USER_SEARCH_ADD_ERROR,
    USER_SEARCH_CLEAR_ERROR,
    USER_SEARCH_FETCH,
    USER_SEARCH_UPDATE,
} from '../shared/constants/actions';

const { getErrors } = makeAsyncSelectors('portal');

export const userSearchInitialState = {
    term: '',
    validationErrors: [],
    ...asyncDefaults({}),
};

const searchUser = makeAsyncReducer(USER_SEARCH_FETCH);

const validation = (state, { type, payload = [] }) => {
    switch (type) {
        case USER_SEARCH_UPDATE:
            return { ...state, term: payload };
        case USER_SEARCH_ADD_ERROR:
            return {
                ...state,
                validationErrors: [...(state.validationErrors || []), ...payload],
            };
        case USER_SEARCH_CLEAR_ERROR:
            return { ...state, validationErrors: [] };
        default:
            return state;
    }
};

export default mergeReducers(
    userSearchInitialState,
    RIDB_ACTION_PREFIX,
    searchUser,
    validation
);

export const getUserSearchTerm = (state) => get(state, 'portal.userSearch.term');

export const getUserSearchErrors = (state) => {
    const errors = [...(get(state, 'portal.userSearch.validationErrors') || [])];
    const { userSearch } = getErrors(state, 'userSearch');
    return userSearch ? errors.concat(userSearch) : errors;
};
