/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { apiHelper, endpoints, ResponseCodes } from '../api';
import { keysToCamelCase } from './object';
import Auth, { AuthTargetedTypes } from '../services/auth';
import { AuthLocalStorageKeys } from '../constants/auth';
import { LocalStorage } from '../services/localStorage';

export const login = async (params: any) => {
    // Clear all auth keys on login
    Object.keys(AuthLocalStorageKeys).forEach((key) =>
        LocalStorage.removeItem(AuthLocalStorageKeys[key])
    );
    let response;
    const { auth } = apiHelper;
    try {
        // Fetch external account data for the user; login using email and password
        response = await auth(endpoints.login()).post(params);
        const { account, access_token } = response.data;
        Auth.target(AuthTargetedTypes.TOKEN, access_token);
        // Fetch the developers apiKey data; Uses the token received from the login above
        response = await auth(endpoints.keys()).get();
        const apiKey = keysToCamelCase(response.data.api_key);
        const isDeveloper = apiKey.apiKey !== '';
        const user = {
            loggedIn: true,
            isDeveloper,
            isTier1Developer: isDeveloper && apiKey.tier1Access,
            isTier2Developer: isDeveloper && apiKey.tier2Access,
            account: keysToCamelCase(account),
            apiKey,
        };
        Auth.target(AuthTargetedTypes.USER, user);
        Auth.target(AuthTargetedTypes.API_KEY, apiKey.apiKey);
        // Fetch the developers apiKey data; Uses the token received from the login above
        response = await auth(endpoints.machineKeys()).get();
        const machineKeys = keysToCamelCase(response.data.api_keys);
        Auth.target(AuthTargetedTypes.API_KEYS, machineKeys);
        return { data: user };
    } catch (e) {
        Auth.clearData();
        const { response } = e;
        if (response) {
            const { status, data } = response;
            // MFA enabled?
            if (
                response &&
                status === ResponseCodes.BAD_REQUEST &&
                data.error === 'mfa'
            ) {
                throw new Error('mfa');
            }
        }
        throw new Error('unauthorized');
    }
};
