/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    asyncDefaults,
    makeAsyncReducer,
    makeAsyncResetReducer,
    mergeReducers,
} from '../shared/utils/async';
import {
    FETCH_EVENTS,
    RIDB_ACTION_PREFIX,
    RESET_EVENTS,
} from '../shared/constants/actions';

const fetchEvents = makeAsyncReducer(FETCH_EVENTS);

const resetEvents = makeAsyncResetReducer(RESET_EVENTS, []);

export default mergeReducers(
    asyncDefaults([]),
    RIDB_ACTION_PREFIX,
    fetchEvents,
    resetEvents
);

export const getEvents = ({
    portal: {
        events: { data },
    },
}) => data;
