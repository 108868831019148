/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useRef, useState } from 'react';
import { get, map, snakeCase, toString } from 'lodash';
import {
    Box,
    FlexCol,
    FlexRow,
    Label,
    Notification,
    Spinner,
    TextArea,
    TextField,
} from 'sarsaparilla';
import { useDispatch } from 'react-redux';
import {
    DESCRIPTION_PATH,
    DIRECTIONS_PATH,
    ACCESSIBILITY_TEXT_PATH,
    EMAIL_PATH,
    FEE_DESCRIPTION_PATH,
    ID_PATH,
    KEYWORDS_PATH,
    LATITUDE_PATH,
    LEGACY_ID_PATH,
    LONGITUDE_PATH,
    NAME_PATH,
    ORG_ID_PATH,
    ORG_REF_ID_PATH,
    PHONE_PATH,
    STAY_LIMIT_PATH,
} from '../../../../shared/constants/asset/asset';
import {
    isValidEmail,
    isValidLatitude,
    isValidLongitude,
    isValidPhoneNumber,
    stripHTML,
} from '../../../../shared/utils/string';
import { Globals } from '../../../../shared/globals';
import AnyObject = Globals.AnyObject;
import { IOrganization } from '../../../../shared/types/asset/organization';
import { addChange, clearChanges, getRef, removeRef } from '../../../../actions/changes';
import AssetEditCheckBoxInput from '../inputs/AssetEditCheckBoxInput';
import { SelectInput } from '../../../../containers/StewardAsset/StewardAssetEditPage/ValidationWrapper';
import { reduceAndRestructure } from '../../../../shared/utils/asset';
import TargetedEvent = Globals.TargetedEvent;
import SuggestSearch from '../SuggestSearch';
import '../../../../scss/components/StewardAssetEditPage/tabs/_AssetTab.scss';
import AnyFunction = Globals.AnyFunction;

const getTextInputs = (name: string, isFacility: boolean) => [
    {
        label: `${name} Name`,
        required: true,
        field: NAME_PATH,
    },
    {
        label: `${name} ID`,
        readOnly: true,
        field: ID_PATH,
    },
    {
        label: 'Email',
        field: EMAIL_PATH,
        isValid: (email: string) => isValidEmail(email),
        errorText: `${isFacility ? 'Facility' : 'Rec Area'} Email is invalid`,
    },
    {
        label: 'Fee Description',
        field: FEE_DESCRIPTION_PATH,
    },
    {
        label: 'Legacy ID',
        field: LEGACY_ID_PATH,
    },
    {
        label: 'Legacy ID Organization',
        field: ORG_REF_ID_PATH,
    },
    {
        label: 'Latitude',
        field: LATITUDE_PATH,
        isValid: (lat: string) => isValidLatitude(lat),
        errorText: 'Latitude is invalid',
    },
    {
        label: 'Longitude',
        field: LONGITUDE_PATH,
        isValid: (long: string) => isValidLongitude(long),
        errorText: 'Longitude is invalid',
    },
    {
        label: 'Phone',
        field: PHONE_PATH,
        isValid: (phone: string) => isValidPhoneNumber(phone),
        errorText: 'Phone number is invalid',
    },
];

const textBoxInputs = [
    {
        label: 'Description',
        field: DESCRIPTION_PATH,
        required: true,
        fullWidth: true,
        maxChar: 4000,
    },
    {
        label: 'Directions',
        field: DIRECTIONS_PATH,
        required: true,
        fullWidth: true,
        maxChar: 4000,
    },
    {
        label: `ADA Accessibility Info`,
        field: ACCESSIBILITY_TEXT_PATH,
        fullWidth: true,
        maxChar: 4000,
    },
    {
        label: 'Keywords',
        field: KEYWORDS_PATH,
        maxChar: 400,
    },
    {
        label: 'Stay Limit',
        field: STAY_LIMIT_PATH,
        maxChar: 400,
    },
];

const checkBoxInputs = (isFacility = true): any[] => {
    return isFacility
        ? [
              { field: 'enabled', label: 'Enabled' },
              { field: 'adaAccess', label: 'Facility ADA Access' },
          ]
        : [{ field: 'enabled', label: 'Enabled' }];
};

const getCheckBoxValue = (val: string) => {
    return !(!val || val === 'No');
};

const getSelectInputs = (orgOptions: any[]) => [
    {
        label: 'Organization',
        key: 'orgId',
        field: ORG_ID_PATH,
        required: true,
        fullWidth: true,
        size: Math.max(1, (orgOptions || []).length),
        options: orgOptions || [],
    },
];

type AssetTabProps = {
    value: AnyObject;
    assetDetails: {
        name: string;
        singular: string;
        plural: string;
    };
    orgOptions: IOrganization[];
    isSuccessful: boolean;
    isLoading: boolean;
    onAssetUpdate: AnyFunction;
    onInvalidItemsChange?: AnyFunction;
    onLastAssetRef?: AnyFunction;
    lastRef?: string;
    isNew?: boolean;
};

function AssetTab(props: Partial<AssetTabProps>) {
    const {
        value = {},
        assetDetails: { name, plural },
        orgOptions,
        isSuccessful,
        isLoading,
        onAssetUpdate,
        onInvalidItemsChange,
        onLastAssetRef,
        lastRef,
        isNew = false,
    } = props;
    const dispatch = useDispatch();
    const [pendingValue, updateValue] = useState(value);
    const [ref, updateRef] = useState(null);
    const [allRefs, updateAllRefs] = useState([]);
    const { id: valueId } = value;
    const requiredPaths = [NAME_PATH, ORG_ID_PATH, DESCRIPTION_PATH, DIRECTIONS_PATH];
    const required = reduceAndRestructure(requiredPaths, value);
    const [invalidItems, updateInvalidItems] = useState([]);
    if (!allRefs.includes(ref)) {
        updateAllRefs(allRefs.concat([ref]));
    }

    // if !isNew, updates state value assetLastRef to highest-numbered ref in allRefs
    // allRefs expected to be strings of the form '.*ref-[0-9]*', selects the one with the greatest numeral component
    // but as far as I can tell, isNew is always false
    useEffect(() => {
        if (!isNew) {
            return () => {
                const filtered = allRefs.filter((r) => !!r);
                if (!filtered.length) {
                    return;
                }
                const max = Math.max(...filtered.map((r) => +r.split('ref-')[1]));
                onLastAssetRef(max);
            };
        }
    }, [allRefs]);

    const isFacilityType = name === 'Facility';

    useEffect(() => {
        updateValue(value); // updates state value pendingValue to assetInState (which is what 'value' is here)
        const newRef = dispatch(
            getRef(
                plural,
                { id: valueId },
                -1, //sort
                required
            )
        ) as unknown as string;
        if (lastRef !== newRef && !isNew) {
            // only now do we clear that old ref, to maintain validation.
            dispatch(removeRef(lastRef));
        }
        updateRef(newRef);
        return () => {
            // this will remove all refs on unmount,
            // except the one about to be created,
            // which needs to be removed after asset tab is refocused
            if (!isNew) {
                allRefs.forEach((r) => dispatch(removeRef(r)));
            }
        };
    }, [value]);

    useEffect(() => {
        onInvalidItemsChange(invalidItems);
    }, [invalidItems]);

    const selectInputs = getSelectInputs(orgOptions);
    const textInputs = getTextInputs(name, isFacilityType);
    const checkboxInputs = checkBoxInputs(isFacilityType);

    const handleChange = (event: TargetedEvent, path: string, isRecArea = false) => {
        const { value: newValue } = event.target;
        const idField = get(event, 'target.dataset.idField');
        const field = get(event, 'target.dataset.field');
        const { id } = newValue;
        const recAreaChange = {
            [field]: [{ id, [path]: newValue[path] }],
            [idField]: id,
        };
        const facilityChange = { [path || field]: newValue };
        const change = isRecArea ? recAreaChange : facilityChange;

        const newValues = { ...pendingValue, ...change };

        updateValue(newValues);
        onAssetUpdate(newValues, 'assets');
        if (isNew || field === 'adaAccess') {
            dispatch(addChange(ref, change));
        }
    };

    const checkInvalidItems = (items: any[]) => {
        items.forEach(({ field, isValid, errorText }) => {
            const strippedValue = stripHTML(toString(get(pendingValue, field, '')));
            const isInvalid =
                (isValid && strippedValue && !isValid(strippedValue)) === true;
            const itemInState = invalidItems.filter((item) => item.field === field);

            if (
                isInvalid &&
                itemInState.length === 1 &&
                itemInState[0].invalid === false
            ) {
                const newItems = invalidItems
                    .filter(
                        (item) => item.field !== field && item.errorText !== errorText
                    )
                    .concat([{ field, errorText, invalid: true }]);
                updateInvalidItems(newItems);
            } else if (itemInState.length === 0 && isInvalid) {
                const newItems = [...invalidItems].concat([
                    { field, invalid: isInvalid, errorText },
                ]);
                updateInvalidItems(newItems);
            } else if (itemInState.length === 1 && !isInvalid && itemInState[0].invalid) {
                const newItems = invalidItems
                    .filter(
                        (item) => item.field !== field && item.errorText !== errorText
                    )
                    .concat([{ field, errorText, invalid: false }]);
                updateInvalidItems(newItems);
            }
        });
    };
    checkInvalidItems(textInputs);
    const query = {
        limit: 10,
        assetTypes: ['recarea'],
        orgIds: orgOptions.map((org) => org.value),
    };

    if (isLoading) {
        return <Spinner isCentered={false} size="xl" />;
    }
    return (
        <Box
            className="asset-tab-container"
            padding="md"
            border="gray"
            background="white"
            marginY="md"
        >
            {isSuccessful && (
                <Notification type="success">Submission successful</Notification>
            )}

            <FlexRow>
                {map(
                    textInputs,
                    ({ field, label, readOnly, required, isValid, errorText }) => {
                        const strippedValue = stripHTML(
                            toString(get(pendingValue, field, ''))
                        );
                        const isInvalid =
                            (isValid && strippedValue && !isValid(strippedValue)) ===
                            true;
                        return (
                            <FlexCol md={6} key={snakeCase(field)}>
                                <TextField
                                    id={field}
                                    label={label}
                                    className="mb-1"
                                    isInvalid={isInvalid}
                                    errorText={isInvalid ? errorText : void 0}
                                    value={strippedValue}
                                    onChange={(e) => handleChange(e, field)}
                                    isDisabled={readOnly}
                                    isRequired={required}
                                />
                            </FlexCol>
                        );
                    }
                )}
            </FlexRow>

            {name === 'Facility' && (
                <div>
                    <Label htmlFor="parentRecArea">Parent Rec Area</Label>
                    <SuggestSearch
                        id="parentRecArea"
                        field="recareas"
                        idField="recAreaId"
                        value={get(pendingValue, 'recareas[0]')}
                        query={query}
                        placeholder="search Rec Areas by name..."
                        onChange={(e) => handleChange(e, 'name', true)}
                    />
                </div>
            )}

            <FlexRow>
                {map(
                    checkboxInputs,
                    ({ field, label }) =>
                        get(pendingValue, field) !== void 0 && (
                            <FlexCol md={6} key={field}>
                                <AssetEditCheckBoxInput
                                    value={getCheckBoxValue(get(pendingValue, field))}
                                    label={label}
                                    field={field}
                                    onChange={(e) => handleChange(e, field)}
                                />
                            </FlexCol>
                        )
                )}
            </FlexRow>

            {map(selectInputs, (selectInputProps) => (
                <SelectInput
                    key={snakeCase(selectInputProps.field)}
                    {...selectInputProps}
                    changeRef={ref}
                    onChange={(e) => handleChange(e, selectInputProps.field)}
                    value={get(pendingValue, selectInputProps.field)}
                />
            ))}

            {map(textBoxInputs, ({ field, label, required }) => (
                <TextArea
                    key={snakeCase(field)}
                    id={field}
                    label={label}
                    onChange={(e) => handleChange(e, field)}
                    value={get(pendingValue, field, '')}
                    isRequired={required}
                    rows={5}
                />
            ))}
        </Box>
    );
}

export default AssetTab;
