/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import API from '../shared/api';
import {
    ENABLE_DEVELOPER_ACCESS,
    FETCH_ACCOUNT,
    UPDATE_ACCOUNT,
} from '../shared/constants/actions';
import { keysToCamelCase } from '../shared/utils/object';

export const fetchAccount = makeAsyncAction(
    FETCH_ACCOUNT,
    (emailOrId) => () => API.getAccount(emailOrId).then(keysToCamelCase)
);

export const enableDeveloperAccess = makeAsyncAction(
    ENABLE_DEVELOPER_ACCESS,
    (accountId) => () => API.enableDeveloperAccess(accountId)
);

export const updateAccount = makeAsyncAction(
    UPDATE_ACCOUNT,
    (details) => () => API.updateAccount(details),
    'ok'
);
