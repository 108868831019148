/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { searchDefaults } from '../shared/constants/search';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';
import { RIDB_ACTION_PREFIX, SEARCH } from '../shared/constants/actions';

const search = makeAsyncReducer(SEARCH);

export default mergeReducers(asyncDefaults(searchDefaults), RIDB_ACTION_PREFIX, search);

export const getSearch = (state) => state.portal.search.data;
