/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox } from 'sarsaparilla';
import { IActivity } from '../../../shared/types/asset/activities';
import { addChange, clearChange, getRef } from '../../../actions/changes';
import { Globals } from '../../../shared/globals';
import TargetedEvent = Globals.TargetedEvent;
import AnyFunction = Globals.AnyFunction;
import emptyFunction = Globals.emptyFunction;

type ActivityOptionProps = {
    activity: {
        id: number;
        name: string;
    };
    value: IActivity;
    activityType: string;
    onChange: AnyFunction;
    isNew: boolean;
    savedActivityIds?: number[];
};

function ActivityOption(props: ActivityOptionProps) {
    const {
        activityType,
        activity: { id, name },
        value = null,
        onChange = emptyFunction,
        isNew = false,
        savedActivityIds = [],
    } = props;
    const [ref, setRef] = useState(null);
    const [isChecked, toggleSelection] = useState(value !== null);
    const dispatch = useDispatch();

    useEffect(() => {
        setRef(dispatch(getRef(activityType, value || {})));
    }, []);

    const handleChange = (event: TargetedEvent<{ checked: boolean }>) => {
        const newIsChecked = event.target.checked;
        toggleSelection(newIsChecked);
        onChange({ selected: newIsChecked, activityId: id });
        if (newIsChecked && value === null) {
            dispatch(addChange(ref, { activityId: id }));
        } else if (value !== null && !isNew && savedActivityIds.includes(id)) {
            dispatch(addChange(ref, { _delete: true }));
        } else {
            dispatch(clearChange(ref));
        }
    };

    return (
        <Checkbox
            id={name}
            label={name}
            onChange={handleChange}
            value="controlled"
            isChecked={isChecked}
        />
    );
}

export default ActivityOption;
