/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { capitalize, sortBy, uniqueId } from 'lodash';
import {
    ADD_PENDING_CHANGES,
    CLEAR_PENDING_CHANGE,
    CLEAR_PENDING_CHANGES,
    INIT_REF,
    REMOVE_REF,
    REMOVE_REFS,
    UPDATE_CHANGE_ID,
    UPDATE_REQUIRED_CHANGES,
    VALIDATION_ADD_ERROR,
    VALIDATION_CLEAR_ERRORS,
} from '../shared/constants/actions';
import { buildAssetAPICall, decodePostResponseId } from '../shared/utils/asset';
import { apiResourceFromVerb } from '../shared/api';
import { validate } from '../shared/utils/validation';
import {
    saveAddError,
    saveClearErrors,
    saveStatusClear,
    saveStatusStart,
    saveStatusStep,
} from './saveStatus';
import { IValidationChange } from '../shared/types/validation';
import {
    AssetAPICall,
    IAssetChange,
    StewardAssetInvalidItem,
} from '../shared/types/asset/asset';
import { ADDRESS1_PATH } from '../shared/constants/asset/address';

export const getRef =
    (type, ids, sort = 0, required = {}) =>
    (dispatch) => {
        const ref = uniqueId('ref-');
        dispatch({
            type: INIT_REF,
            payload: { ref, type, ids, sort, required },
        });
        return ref;
    };

export const removeRef = (ref) => (dispatch) =>
    dispatch({
        type: REMOVE_REF,
        payload: { ref },
    });

export const removeRefs = () => (dispatch) =>
    dispatch({
        type: REMOVE_REFS,
    });

export const updateChangeId = (ref, id) => (dispatch) =>
    dispatch({
        type: UPDATE_CHANGE_ID,
        payload: { ref, id },
    });

export const updateRequiredFields =
    (ref, required = {}) =>
    (dispatch) =>
        dispatch({
            type: UPDATE_REQUIRED_CHANGES,
            payload: { ref, required },
        });

export const addChange = (ref, changes) => (dispatch) =>
    dispatch({
        type: ADD_PENDING_CHANGES,
        payload: { ref, changes },
    });

export const addValidationError = (ref, field, errors) => (dispatch) =>
    dispatch({
        type: VALIDATION_ADD_ERROR,
        payload: { ref, field, errors },
    });

export const clearValidationErrors = () => (dispatch) =>
    dispatch({
        type: VALIDATION_CLEAR_ERRORS,
    });

const getFormNameFromField = (name: string) => {
    switch (name) {
        case ADDRESS1_PATH:
            return 'Street Address';
        default:
            return name;
    }
};

export const validateChanges = (allChanges: any[]) => (dispatch) => {
    let successful = true;
    const allInvalidChanges = allChanges.filter((c) => c.invalid);
    if (allInvalidChanges.length > 0) {
        allInvalidChanges.forEach((change) => {
            dispatch(
                saveAddError({
                    error: `${change.field} is invalid`,
                    description: change.errorText,
                })
            );
        });
        successful = false;
    }
    allChanges.forEach((change: IValidationChange & StewardAssetInvalidItem) => {
        // Include required fields
        const {
            required = {},
            changes: chngs = {},
            ref,
            type,
            invalid,
            errorText,
        } = change;
        const filteredRequired = {};
        Object.keys(required).forEach((key) => {
            if (required[key] || !filteredRequired[key]) {
                filteredRequired[key] = required[key];
            }
        });

        const changes = Object.assign(filteredRequired, chngs);
        // If there are ANY changes for the record check it.
        if (Object.keys(changes).length !== 0) {
            const missingFields = [];
            for (const field in changes) {
                if (changes.hasOwnProperty(field)) {
                    if (invalid) {
                        dispatch(
                            saveAddError({
                                error: `${field} is invalid`,
                                description: errorText,
                            })
                        );
                        successful = false;
                        break;
                    }
                    const errors = validate(type, field, changes[field]);
                    if (errors) {
                        dispatch(addValidationError(ref, field, errors));
                        // keep track of required fields that are missing
                        if (
                            errors &&
                            errors.filter((e) => e === 'Required Field').length > 0
                        ) {
                            missingFields.push(field);
                        }
                        successful = false;
                    }
                }
            }
            missingFields.forEach((field) => {
                const errorMessage = `${capitalize(
                    getFormNameFromField(field)
                )} is a required field`;
                dispatch(
                    saveAddError({
                        error: 'Missing required field',
                        description: errorMessage,
                    })
                );
            });
        }
    });

    return successful;
};

export const clearChange = (ref) => (dispatch) =>
    dispatch({
        type: CLEAR_PENDING_CHANGE,
        payload: { ref },
    });

export const clearChanges = () => (dispatch) =>
    dispatch({
        type: CLEAR_PENDING_CHANGES,
    });

type ChangeType = 'activities' | 'other' | 'addresses' | 'links' | 'images' | 'events';

export function getTypeFromChange(type: string): ChangeType {
    switch (type) {
        case 'facilityactivities':
        case 'recareaactivities':
            return 'activities';
        case 'facilityaddresses':
        case 'recareaaddresses':
            return 'addresses';
        case 'facilitylinks':
        case 'recarealinks':
            return 'links';
        case 'facilitymedia':
        case 'recareamedia':
            return 'images';
        case 'facilityevent':
        case 'recareaevent':
            return 'events';
        default:
            return 'other';
    }
}

const filterEmptyItems = (type: ChangeType): ((c: any) => boolean) => {
    switch (type) {
        case 'activities':
            return (c) =>
                getTypeFromChange(c.type) !== type ||
                (getTypeFromChange(c.type) === type &&
                    (c.changes.activityId || c.changes._delete));
        case 'addresses':
        case 'links':
        case 'images':
        case 'events':
            const hasChanges = ({ changes = {}, required = {} }) => {
                for (const key in changes) {
                    if (required[key] && required[key] !== changes[key]) return true;
                }
                return false;
            };
            return (c) =>
                getTypeFromChange(c.type) !== type ||
                (getTypeFromChange(c.type) === type &&
                    Object.keys(c.changes || {}).length > 0) ||
                hasChanges(c);
    }
};

const filterEditEmpty = (isEdit: boolean) => {
    return !isEdit
        ? (c) => true
        : (c) =>
              c.invalid ||
              getTypeFromChange(c.type) === 'links' ||
              (c.ids &&
                  (c.ids.id ||
                      c.ids.activityId ||
                      Object.keys(c.changes || {}).length > 0));
};

export const filterOldItems =
    (isEdit = false, changes = []) =>
    (change) => {
        if (isEdit) return true;
        if (
            !change.validation ||
            !change.ref ||
            !(change.type === 'recareas' || change.type === 'facilities')
        )
            return true;
        if (!change.changes) {
            return false;
        }
        const currentRefNumber = +change.ref.split('ref-')[1];
        const othersOfSameType = changes
            .filter((c) => c.type === change.type && c.ref)
            .filter((c) => {
                const refNumber = +c.ref.split('ref-')[1];
                return currentRefNumber < refNumber;
            })
            .sort((a, b) => {
                const aRef = +a.ref.split('ref-')[1];
                const bRef = +b.ref.split('ref-')[1];
                return aRef > bRef ? 1 : aRef === bRef ? 0 : -1;
            });
        // true = item should stay, false  = item should be filtered out
        for (const item of othersOfSameType) {
            if (!item.validation) return false;
            for (const key in change.validation) {
                if (item.changes[key]) return false;
            }
        }
        return true;
    };

// Save/Sync all changes to the server
export const saveChanges =
    (allChanges: any[], id = '', isEdit = false) =>
    async (dispatch) => {
        const changes = sortBy(
            allChanges
                .filter(
                    ({ required, changes, invalid }) =>
                        invalid === true ||
                        (required && Object.keys(required).length > 0) ||
                        (changes && Object.keys(changes).length > 0)
                )
                .filter(filterEmptyItems('activities'))
                .filter(filterEmptyItems('addresses'))
                .filter(filterEmptyItems('links'))
                .filter(filterEmptyItems('images'))
                .filter(filterEmptyItems('events'))
                .filter(filterEditEmpty(isEdit)),
            ['sort']
        );
        dispatch(clearValidationErrors());
        if (
            changes.length === 1 &&
            (!changes[0] || !changes[0].ids || !changes[0].ids.id) &&
            !changes[0].changes &&
            !isEdit &&
            (!changes[0].required || Object.keys(changes[0].required).length < 1)
        ) {
            dispatch(
                saveAddError({ error: 'No changes made', description: 'No changes made' })
            );
            throw new Error('No changes have been made.');
        }
        if (!dispatch(validateChanges(changes))) {
            throw new Error('Some validation errors during save.');
        }
        dispatch(saveClearErrors());
        dispatch(saveStatusStart(changes.length));

        let assetId = id;
        let errorsEncountered = false;

        const callApi = async (call: AssetAPICall) => {
            const { verb, url, data, type, description, ref } = call;
            let response;

            try {
                const apiCall = apiResourceFromVerb[verb];
                response =
                    verb === 'DELETE' ? await apiCall(url) : await apiCall(url, data);
                if (verb === 'POST') {
                    dispatch(updateChangeId(ref, decodePostResponseId(type, response)));
                }
            } catch (error) {
                dispatch(
                    saveAddError({
                        description,
                        error,
                    })
                );
                errorsEncountered = true;
                return error;
            }

            if (type === 'facilities' || type === 'recareas') {
                assetId = response.data.FACILITYID || response.data.RECAREAID;
            }

            dispatch(saveStatusStep());
            dispatch(clearChange(call.ref));

            return response;
        };
        await changes
            // Filter out any changes that don't have any changes.
            .filter(({ changes }) => changes && Object.keys(changes).length > 0)
            // Call callApi sequentially with all changes and the assetId that may or may not have been generated
            // by the first change
            .reduce(
                (all, change: IAssetChange) =>
                    all.then((v) => {
                        let call;
                        try {
                            call = buildAssetAPICall(change, +assetId);
                        } catch (err: any) {
                            dispatch(
                                addValidationError(change.ref, err.field, err.messages)
                            );

                            errorsEncountered = true;
                            return Promise.all([...v]);
                        }

                        return Promise.all([...v, callApi(call)]);
                    }),
                Promise.resolve([])
            );

        dispatch(saveStatusClear());

        if (errorsEncountered) {
            throw new Error('Some errors during save.');
        }

        return Promise.resolve(assetId);
    };
