/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { uniqueId } from 'lodash';
import { IItemKeyConfig, ItemTranslator } from './itemTranslator';
import { Globals } from '../../globals';
import {
    decodeFacilityActivityKeys,
    decodeFacilityAddressKeys,
    encodeFacilityActivityKeys,
    encodeFacilityAddressKeys,
    facilityDecodeKeys,
    facilityEncodeKeys,
    facilityRecordMappings,
} from '../../constants/asset/facility';
import { FacilityDecodeRecArea } from '../../types/asset/recArea';
import { IFacilityDecodeRecord } from '../../types/asset/facility';
import { IAssetDecodeRecord } from '../../types/asset/asset';
import AnyObject = Globals.AnyObject;

const facilityItemConfig: IItemKeyConfig = {
    activity: {
        encode: encodeFacilityActivityKeys,
        decode: decodeFacilityActivityKeys,
    },
    address: {
        encode: encodeFacilityAddressKeys,
        decode: decodeFacilityAddressKeys,
    },
    main: {
        decode: facilityDecodeKeys,
        encode: facilityEncodeKeys,
        recordMappings: facilityRecordMappings,
    },
};

class Facility extends ItemTranslator {
    decode = (record: IAssetDecodeRecord) => {
        const { decode: decodeKeys } = this.keys.main;
        const { RECAREA, FACILITYADDRESS, ACTIVITY } = record as IFacilityDecodeRecord;
        const asset = {
            _key: uniqueId('asset-'),
            events: [],
            recareas: this.decodeConverse(RECAREA),
            addresses: this.decodeAddresses(FACILITYADDRESS),
            activities: this.decodeActivities(ACTIVITY),
        };
        return this.translate(decodeKeys, record, asset);
    };

    encode = (record: AnyObject) => {
        const facility = {};
        const { encode: encodeKeys, recordMappings } = this.keys.main;
        Object.keys(encodeKeys).forEach((key) => {
            const responseKey = encodeKeys[key];
            const mapFn = recordMappings[responseKey];
            const value = record[key];
            facility[responseKey] = mapFn ? mapFn(value) : value;
        });

        return facility;
    };

    // Facility.decodeRecAreas
    decodeConverse = (records: FacilityDecodeRecArea[]) => {
        return records.map((record) => ({
            _key: uniqueId('recarea-'),
            id: record.RecAreaID,
            name: record.RecAreaName,
            link: record.ResourceLink,
        }));
    };
}

export default new Facility(facilityItemConfig);
