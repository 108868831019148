/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import {
    FETCH_ASSET,
    RIDB_ACTION_PREFIX,
    RESET_ASSET,
} from '../shared/constants/actions';
import {
    asyncDefaults,
    makeAsyncReducer,
    makeAsyncResetReducer,
    mergeReducers,
} from '../shared/utils/async';

const fetchAsset = makeAsyncReducer(FETCH_ASSET);

const resetAsset = makeAsyncResetReducer(RESET_ASSET, {});

export const getAsset = (state) => get(state, 'portal.asset.data');

export default mergeReducers(
    asyncDefaults({}),
    RIDB_ACTION_PREFIX,
    fetchAsset,
    resetAsset
);
