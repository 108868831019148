/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { uniqueId } from 'lodash';
import { Button, Spinner, Box } from 'sarsaparilla';
import { IAddress } from '../../../../shared/types/asset/address';
import { addressDefaults } from '../../../../shared/constants/asset/address';
import AddressEdit from '../../../../containers/StewardAsset/StewardAssetEditPage/assetEdit/AddressEdit';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import '../../../../scss/components/StewardAssetEditPage/tabs/_AddressesTab.scss';
import { Globals } from '../../../../shared/globals';
import AnyFunction = Globals.AnyFunction;

type AddressesTabProps = {
    value: {
        addresses: IAddress[];
    };
    addressType: string;
    isLoading: boolean;
    onAssetUpdate?: AnyFunction;
};

class AddressesTab extends Component<AddressesTabProps, { addresses: IAddress[] }> {
    constructor(props) {
        super(props);
        const { value: { addresses } = { addresses: void 0 } } = props;
        this.state = { addresses };
    }

    addBlankAddress = () => {
        const address = {
            ...addressDefaults,
            _key: uniqueId('address-'),
        };

        this.setState((prevState) => {
            return { ...prevState, addresses: [...prevState.addresses, address] };
        });
    };

    removeAddress = (key) => {
        const { onAssetUpdate } = this.props;
        this.setState((prevState) => {
            const addresses = prevState.addresses.filter(({ _key }) => _key !== key);
            if (onAssetUpdate) {
                onAssetUpdate(addresses, 'addresses');
            }
            return {
                ...prevState,
                addresses,
            };
        });
    };

    handleChange = (address: IAddress) => {
        const { addresses } = this.state;
        const { onAssetUpdate } = this.props;
        const filteredAddresses = addresses.filter((a) => a._key !== address._key);
        filteredAddresses.push(address);
        this.setState({ addresses: filteredAddresses }, () => {
            if (onAssetUpdate) {
                onAssetUpdate(filteredAddresses, 'addresses');
            }
        });
    };

    render() {
        const { addressType, isLoading } = this.props;
        const { addresses } = this.state;

        if (isLoading) {
            return <Spinner isCentered={false} size="xl" />;
        }

        return (
            <div className="tab-body asset-addresses">
                {addresses.map((address, index) => (
                    <Box
                        key={address._key}
                        className="mb-2"
                        padding={['lg']}
                        background="white"
                        border="gray"
                    >
                        <AddressEdit
                            index={index}
                            key={address._key}
                            value={address}
                            addressType={addressType}
                            onChange={this.handleChange}
                            onRemove={this.removeAddress}
                        />
                    </Box>
                ))}

                <Button className="add-button" onClick={this.addBlankAddress}>
                    Add new address
                </Button>
            </div>
        );
    }
}

export default AddressesTab;
