/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState, useEffect } from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SkipNavContent } from 'sarsaparilla';
import { fetchAsset } from '../../actions/asset';
import { getState } from '../../reducers';
import TextDisplay from '../../components/StewardAsset/StewardAssetDisplay/TextDisplay';
import LoadingWrapper from '../../shared/components/LoadingWrapper';
import { getAsset } from '../../reducers/asset';
import { Globals } from '../../shared/globals';
import { StateStatus } from '../../shared/utils/async';
import { recAreaDisplayFields } from '../../shared/constants/asset/recArea';
import {
    FACILITY_URL,
    facilityDisplayFields,
} from '../../shared/constants/asset/facility';
import Alert from '../../shared/components/Alert';
import AnyObject = Globals.AnyObject;
import AnyFunction = Globals.AnyFunction;
import RidbLink from '../../shared/components/RidbLink';
import mergeDefaults = Globals.mergeDefaults;
import '../../scss/containers/_StewardAssetDisplayPage.scss';

const defaultProps = { asset: {} };

const facilityNotFound = {
    id: '1',
    level: 'warning',
    title: 'Warning',
    message: 'Facility not found.',
};

type StewardAssetDisplayPageProps = {
    match?: any;
    asset: AnyObject;
    states?: any;
    fetchData?: AnyFunction;
};

function StewardAssetDisplayPage(props: StewardAssetDisplayPageProps) {
    const {
        match: {
            params: { assetType, assetId },
        },
        fetchData: fetch,
        asset,
        states: { asset: statesAsset },
    } = mergeDefaults(props, defaultProps);
    const [apiLoading, setApiLoading] = useState(false);
    const [notFound, setNotFound] = useState(false);

    const assetNeedsUpdate = (assetID: number) => {
        return assetID && get(asset, 'id') !== assetID;
    };

    const fetchData = async (assetID: number) => {
        if (assetNeedsUpdate(assetID) && !apiLoading) {
            try {
                setApiLoading(true);
                await fetch(assetID, { full: true });
            } catch (e) {
                setNotFound(true);
            } finally {
                setApiLoading(false);
            }
        }
    };

    const getButtons = (assetType: string, assetId: number, reservable: boolean) => {
        const editInHubURL = `${process.env.HUB_EDIT_URL}/${assetId}`;
        return (
            <div className="display-buttons">
                <RidbLink
                    className="rec-button-tertiary"
                    to={`/data-steward/${assetType}/search`}
                >
                    Back to search
                </RidbLink>
                {reservable ? (
                    <a
                        className="rec-button-primary left-margin-15"
                        target="_blank"
                        rel="noreferrer"
                        href={editInHubURL}
                    >
                        Edit in the Hub
                    </a>
                ) : (
                    <RidbLink
                        className="rec-button-primary left-margin-15"
                        to={`/data-steward/${assetType}/${assetId}/edit`}
                    >
                        Edit
                    </RidbLink>
                )}
            </div>
        );
    };

    useEffect(() => {
        fetchData(assetId);
    }, []);

    const displayFields =
        assetType === FACILITY_URL ? facilityDisplayFields : recAreaDisplayFields;

    const loading = statesAsset !== StateStatus.READY;
    const buttons = getButtons(assetType, assetId, get(asset, 'reservable', true));

    const getValue = (field) => {
        const val = get(asset, field, '');
        return typeof val === 'boolean' ? (val ? 'Yes' : 'No') : val;
    };

    return (
        <SkipNavContent>
            <main role="main" className="steward-display">
                {notFound && (
                    <>
                        <Alert alert={facilityNotFound} />
                        {buttons}
                    </>
                )}
                {!notFound && (
                    <>
                        {buttons}
                        <LoadingWrapper loading={loading} size="lg">
                            <div className="display-body">
                                {displayFields.map(
                                    ({
                                        field,
                                        label,
                                        arrayFields,
                                        arrayIdField,
                                        fullWidth,
                                    }) => (
                                        <TextDisplay
                                            key={field}
                                            label={label}
                                            field={field}
                                            arrayFields={arrayFields}
                                            arrayIdField={arrayIdField}
                                            fullWidth={fullWidth}
                                            value={getValue(field)}
                                        />
                                    )
                                )}
                            </div>
                        </LoadingWrapper>
                        {buttons}
                    </>
                )}
            </main>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    asset: getAsset(state),
    states: getState(state, 'asset'),
});

const mapDispatchToProps = (dispatch, ownProps) => {
    const fetchData = (id, query) =>
        fetchAsset(ownProps.match.params.assetType, id, query);
    return bindActionCreators({ fetchData }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(StewardAssetDisplayPage);
