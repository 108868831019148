/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { IChangeLogItem } from '../../shared/types/changeLog';
import ChangeLogItem from './ChangeLogItem';

function ChangeLogList({ changeLog }: { changeLog: IChangeLogItem[] }) {
    return (
        <div>
            {changeLog.map(({ title, date, entries }, i) => (
                <ChangeLogItem
                    key={`change-${i}`}
                    title={title}
                    date={date}
                    entries={entries}
                />
            ))}
        </div>
    );
}

export default ChangeLogList;
