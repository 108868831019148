/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { defaults } from 'lodash';
import { makeAsyncAction } from '../shared/utils/async';
import { searchDefaults } from '../shared/constants/search';
import { SEARCH } from '../shared/constants/actions';

export const saveSearch = makeAsyncAction(
    SEARCH,
    (query) => () => Promise.resolve({ data: defaults({}, query, searchDefaults) })
);
