/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { IChangeLogItem } from '../../shared/types/changeLog';

function ChangeLogItem(props: Partial<IChangeLogItem>) {
    const { title, date, entries } = props;
    return (
        <div>
            <p>
                <strong>{title}</strong> <i>{date}</i>
            </p>
            <ul>
                {entries.map((entry, index) => (
                    <li key={`change-entry-${index}`}>{entry}</li>
                ))}
            </ul>
        </div>
    );
}

export default ChangeLogItem;
