/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import API from '../shared/api';
import {
    DISABLE_MACHINE_KEY,
    FETCH_MACHINE_KEYS,
    GENERATE_MACHINE_KEYS,
} from '../shared/constants/actions';

export const generateMachineKeys = makeAsyncAction(
    GENERATE_MACHINE_KEYS,
    () => () => API.generateMachineKeys(),
    'api_key'
);

export const fetchMachineKeys = makeAsyncAction(
    FETCH_MACHINE_KEYS,
    () => () => API.fetchMachineKeys(),
    'api_keys'
);

export const disableMachineKey = makeAsyncAction(
    DISABLE_MACHINE_KEY,
    (id) => () => API.disableMachineKey(id),
    'api_key'
);
