/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Notification, Pagination } from 'sarsaparilla';
import { ICurrentUser } from '../shared/types/auth/currentUser';
import { Globals } from '../shared/globals';
import { IAsset } from '../shared/types/asset/asset';
import { ISearchQuery } from '../shared/types/search';
import { getCurrentUser } from '../reducers/currentUser';
import { getSearch } from '../reducers/search';
import { getAssetCount, getAssets } from '../reducers/assets';
import { saveSearch } from '../actions/search';
import { fetchAssets } from '../actions/asset';
import SearchForm from './SearchForm';
import ReactTable from '../components/ReactTable';
import { handleError } from '../shared/constants/error';

import '../scss/containers/_SearchPage.scss';
import { ReactTableCell } from '../shared/types/reactTable';
import AnyFunction = Globals.AnyFunction;
import { noSearchResults } from '../shared/constants/search';
import RidbLink from '../shared/components/RidbLink';

type SearchPageProps = {
    currentUser?: ICurrentUser;
    searchQuery?: ISearchQuery;
    assets?: IAsset[];
    assetCount?: number;
    saveSearch: AnyFunction;
    fetchAssets: AnyFunction;
};

export class SearchPage extends Component<SearchPageProps> {
    readonly columns = [
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: (c: ReactTableCell) => {
                return this.renderAssetLink(c.row.original);
            },
        },
        {
            Header: 'Type',
            id: 'type',
            accessor: 'type',
        },
        {
            Header: 'Organization',
            id: 'org_name',
            accessor: 'org_name',
        },
        {
            Header: 'Export',
            id: 'export',
            accessor: 'export',
            Cell: (c: ReactTableCell) => {
                return this.renderExportLink(c.row.original);
            },
        },
    ];

    state = {};

    constructor(props: SearchPageProps) {
        super(props);
    }

    componentDidMount() {
        this.doSearch();
    }

    setPage = (page: any) => {
        const { saveSearch, searchQuery } = this.props;
        saveSearch({
            ...searchQuery,
            page,
        }).then(this.doSearch);
    };

    doSearch = () => {
        const { fetchAssets, searchQuery } = this.props;
        fetchAssets(searchQuery).catch((e) => handleError(e, true));
    };

    renderAssetLink = (asset: IAsset) => {
        const { id, type, name } = asset;

        let appRoutes;
        if (name === '') {
            return null;
        }

        switch (type) {
            case 'RecArea':
            case 'Rec Area':
                appRoutes = '/camping/gateways/';
                break;
            case 'Campground':
                appRoutes = '/camping/campgrounds/';
                break;
            default:
                appRoutes = '/camping/poi/';
        }

        const link = process.env.SITE_ROOT + appRoutes + id;
        return (
            <a target="_blank" rel="noopener noreferrer" href={link}>
                {name}
            </a>
        );
    };

    renderExportLink = (asset: IAsset) => {
        const {
            loggedIn,
            isDeveloper,
            apiKey: { apiKey },
        } = this.props.currentUser;

        if (!isDeveloper) {
            return <RidbLink to="/profile">Request an ApiKey</RidbLink>;
        }

        if (!loggedIn) {
            return <RidbLink to="/login">Login to View</RidbLink>;
        }
        const { type, id } = asset;
        const root = `${process.env.RIDB_ROOT}/api/v1`;
        const assetType = type === 'Rec Area' ? '/recareas/' : '/facilities/';
        const queryParams = `?full=true&apikey=${apiKey}`;
        const link = root + assetType + id + queryParams;

        return (
            <a target="_blank" rel="noopener noreferrer" href={link}>
                JSON
            </a>
        );
    };

    render() {
        const {
            assets,
            assetCount,
            searchQuery: { limit, page },
        } = this.props;
        const pages = Math.ceil(assetCount / limit);

        return (
            <main role="main" className="search-page">
                <h1>Advanced RIDB Search</h1>
                <SearchForm
                    onSearch={this.doSearch}
                    inline
                    showLimitSelect
                    dataLength={assets ? assets.length : 0}
                    searchOnChange
                />
                <div className="search-results-table margin-bottom">
                    <ReactTable
                        noDataText={noSearchResults}
                        className="table-no-border"
                        columns={this.columns}
                        data={assets}
                        defaultSortId="name"
                    />
                </div>
                <div className="align-right margin-bottom">
                    {pages ? (
                        <Pagination
                            onChange={this.setPage}
                            activePage={page}
                            pages={pages}
                            wings={2}
                        />
                    ) : null}
                </div>

                <Notification type="info" title="Notice:" headingLevel={3}>
                    <ul>
                        <li>
                            <strong>
                                Please DO NOT share these links as they contain your
                                personal API Key.
                            </strong>
                        </li>
                        <li>
                            For more information on how to use the RIDB data, please visit
                            our&nbsp;
                            <RidbLink to="/docs">API Documentation</RidbLink>.
                        </li>
                        <li>
                            Export All results may include additional Recreation Areas or
                            Facilities as the API queries additional fields.
                        </li>
                    </ul>
                </Notification>
            </main>
        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
    searchQuery: getSearch(state),
    assets: getAssets(state),
    assetCount: getAssetCount(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ saveSearch, fetchAssets }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
