/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import {
    ADD_PENDING_CHANGES,
    CLEAR_PENDING_CHANGE,
    CLEAR_PENDING_CHANGES,
    INIT_REF,
    REMOVE_REF,
    REMOVE_REFS,
    UPDATE_CHANGE_ID,
    UPDATE_REQUIRED_CHANGES,
    VALIDATION_ADD_ERROR,
    VALIDATION_CLEAR_ERRORS,
} from '../shared/constants/actions';

export const changesInitialState = [];

export default (state = changesInitialState, action) => {
    switch (action.type) {
        case INIT_REF:
            return [...state, action.payload];

        // REMOVE_* Removes the entire record
        case REMOVE_REF:
            return state.filter((change) => change.ref !== action.payload.ref);

        case REMOVE_REFS:
            return changesInitialState;

        case UPDATE_CHANGE_ID:
            return state.map((change) =>
                change.ref === action.payload.ref
                    ? { ...change, id: action.payload.id }
                    : change
            );

        case UPDATE_REQUIRED_CHANGES:
            return state.map((change) =>
                change.ref === action.payload.ref
                    ? { ...change, required: action.payload.required }
                    : change
            );

        case ADD_PENDING_CHANGES:
            return state.map((change) => {
                if (change.ref === action.payload.ref) {
                    return {
                        ...change,
                        changes: {
                            ...change.changes,
                            ...action.payload.changes,
                        },
                    };
                }
                return change;
            });

        // CLEAR_PENDING_* Will remove the pending changes but leave the record
        case CLEAR_PENDING_CHANGE:
            return state.map((change) =>
                change.ref === action.payload.ref ? { ...change, changes: {} } : change
            );

        case CLEAR_PENDING_CHANGES:
            return state.map((change) => ({ ...change, changes: {} }));

        case VALIDATION_ADD_ERROR:
            return state.map((change) => {
                if (change.ref === action.payload.ref) {
                    const existingErrors = get(
                        change,
                        `validation.${action.payload.field}`,
                        []
                    );
                    const val = {
                        [action.payload.field]: [
                            ...existingErrors,
                            ...action.payload.errors,
                        ],
                    };
                    const validation = {
                        ...change.validation,
                        ...val,
                    };

                    return { ...change, validation };
                }
                return change;
            });

        case VALIDATION_CLEAR_ERRORS:
            return state.map((change) => ({ ...change, validation: {} }));

        default:
            return state;
    }
};

export const getChangeCount = ({ portal: { changes = [] } }) =>
    changes.reduce((m, c) => m + Object.keys(c.changes || {}).length, 0);
export const getErrorCount = ({ portal: { changes = [] } }) =>
    changes.reduce((m, c) => m + Object.keys(c.validation || {}).length, 0);

export const getChanges = ({ portal: { changes = [] } }, ref) =>
    changes.find((change) => change.ref === ref);

export const getChangesValidation = (state, ref, field) => {
    return get(getChanges(state, ref), `validation.${field}`, []);
};
