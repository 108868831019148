/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { PureComponent } from 'react';
import { map } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button,
    Icons,
    TextField,
    Select,
    SelectOption,
    TextArea,
    FlexRow,
    FlexCol,
    Box,
} from 'sarsaparilla';
import { ILink } from '../../../../shared/types/asset/links';
import { Globals } from '../../../../shared/globals';
import AnyFunction = Globals.AnyFunction;
import { linkTypeOptions } from '../../../../shared/constants/asset/links';
import { reduceAndRestructure } from '../../../../shared/utils/asset';
import TargetedEvent = Globals.TargetedEvent;
import { addChange, clearChange, getRef } from '../../../../actions/changes';
import AnyObject = Globals.AnyObject;
import emptyFunction = Globals.emptyFunction;
import { isValidURL } from '../../../../shared/utils/string';
import { determineNewInvalidItems } from '../../../../shared/utils/validation';

const linkValidation = {
    url: (name: string) => name === '' || isValidURL(name),
};

type LinkEditProps = {
    value?: ILink;
    linkType?: string;
    onRemove?: AnyFunction;
    getRef: AnyFunction;
    addChange: AnyFunction;
    clearChange: AnyFunction;
    onChange?: AnyFunction;
    onInvalidItemsChange?: AnyFunction;
};

type LinkEditState = {
    ref: any;
    record: AnyObject;
    invalidItems: any[];
    deleted: boolean;
};

class LinkEdit extends PureComponent<LinkEditProps, LinkEditState> {
    constructor(props) {
        super(props);

        const { value: record, getRef, linkType } = props;
        const paths = ['url', 'title', 'description'];
        const ref = getRef(
            linkType,
            { id: record.id },
            void 0,
            reduceAndRestructure(paths, record)
        );
        this.state = {
            record,
            ref,
            invalidItems: [],
            deleted: false,
        };
    }

    handleChange = (event: TargetedEvent) => {
        const { name: field, value } = event.target;
        const { onChange = emptyFunction, onInvalidItemsChange = emptyFunction } =
            this.props;
        const change = { [field]: value };
        const { invalidItems } = this.state;
        const newInvalids = determineNewInvalidItems(
            linkValidation,
            field,
            value,
            invalidItems,
            'Link'
        );
        if (newInvalids) {
            this.setState({ invalidItems: newInvalids }, () => {
                onInvalidItemsChange(newInvalids);
            });
        }
        this.setState(
            (prevState) => {
                const record = { ...prevState.record, ...change };
                onChange(record);
                return { ...prevState, record };
            },
            () => this.props.addChange(this.state.ref, change)
        );
    };

    doRemove = () => {
        const {
            props: {
                value: { id, _key },
                addChange,
                clearChange,
                onRemove,
                onInvalidItemsChange,
            },
            state: { ref, invalidItems },
        } = this;
        id ? addChange(ref, { _delete: true }) : clearChange(ref);
        onRemove(_key);
        this.setState({ deleted: true });
        const newInvalids = invalidItems.map((i) => ({ ...i, _delete: true }));
        this.setState({ invalidItems: newInvalids });
        onInvalidItemsChange(newInvalids);
    };

    render() {
        const {
            record: { id, type, url, title, description },
        } = this.state;
        return (
            <Box padding="md" border="gray" background="white" marginY="md">
                <Button
                    appearance="subtle-danger"
                    onClick={this.doRemove}
                    iconBeforeElement={<Icons.IconClose />}
                />

                <FlexRow>
                    <FlexCol xs={12} lg={6}>
                        <Select
                            id={`link-type-${id}`}
                            className="mb-1"
                            label="Link Type"
                            name="type"
                            value={type}
                            onChange={this.handleChange}
                            isRequired
                        >
                            {map(linkTypeOptions, ({ value, label }) => (
                                <SelectOption key={label} value={value}>
                                    {label}
                                </SelectOption>
                            ))}
                        </Select>

                        <TextField
                            id={`link-url-${id}`}
                            className="mb-1"
                            label="Link URL"
                            value={url}
                            errorText={
                                !linkValidation.url(url) ? 'Link URL is invalid' : void 0
                            }
                            isInvalid={!linkValidation.url(url)}
                            name="url"
                            onChange={this.handleChange}
                            isRequired
                        />

                        <TextField
                            id={`link-text-${id}`}
                            className="mb-1"
                            label="Link Text"
                            value={title}
                            name="title"
                            onChange={this.handleChange}
                            isRequired
                        />
                    </FlexCol>

                    <FlexCol xs={12} lg={6}>
                        <TextArea
                            id={`link-description-${id}`}
                            className="mb-1"
                            label="Link Description"
                            name="description"
                            value={description}
                            onChange={this.handleChange}
                            isRequired
                        />
                    </FlexCol>
                </FlexRow>
            </Box>
        );
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRef,
            addChange,
            clearChange,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LinkEdit);
