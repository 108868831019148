/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { get } from 'lodash';
import AssetEditCheckBoxInput from './inputs/AssetEditCheckBoxInput';
import AssetEditNumberInput from './inputs/AssetEditNumberInput';
import AssetEditDateInput from './inputs/AssetEditDateInput';
import { Globals } from '../../../shared/globals';
import AnyObject = Globals.AnyObject;
import AnyFunction = Globals.AnyFunction;

import '../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import {
    FileInput,
    TextBoxInput,
    TextInput,
} from '../../../containers/StewardAsset/StewardAssetEditPage/ValidationWrapper';
import { toStringSafe } from '../../../shared/utils/string';
import emptyFunction = Globals.emptyFunction;

export enum InputPickerTypes {
    TEXTBOX = 'TEXTBOX',
    TEXT = 'TEXT',
    CHECKBOX = 'CHECKBOX',
    DATE = 'DATE',
    NUMBER = 'NUMBER',
    FILE = 'FILE',
    BLANK = 'BLANK',
}

export interface IInputPickerInput {
    type: InputPickerTypes;
    field: string;
    label: string;
    required?: boolean;
    fullWidth?: boolean;
    maxChar?: any;
    fileTypes?: any;
    isValid?: AnyFunction<boolean>;
    errorText?: string;
}

/**
 * Factory function to generate inputs
 */
export function InputPicker(
    input: IInputPickerInput,
    ref: any,
    value: AnyObject,
    onChange: AnyFunction
) {
    const {
        type,
        field,
        label,
        required,
        fullWidth,
        maxChar,
        fileTypes,
        isValid = emptyFunction,
        errorText,
    } = input;
    switch (type) {
        case InputPickerTypes.TEXT:
            const val = toStringSafe(get(value, field));
            return (
                <TextInput
                    key={field}
                    value={val}
                    onChange={onChange}
                    changeRef={ref}
                    required={required}
                    label={label}
                    customValidation={!isValid(val) ? [errorText] : void 0}
                    field={field}
                />
            );
        case InputPickerTypes.NUMBER:
            return (
                <AssetEditNumberInput
                    key={field}
                    value={get(value, field)}
                    onChange={onChange}
                    required={required}
                    label={label}
                    field={field}
                />
            );
        case InputPickerTypes.TEXTBOX:
            return (
                <TextBoxInput
                    key={field}
                    value={get(value, field)}
                    onChange={onChange}
                    changeRef={ref}
                    required={required}
                    fullWidth={fullWidth}
                    label={label}
                    maxChar={maxChar}
                    field={field}
                />
            );
        case InputPickerTypes.CHECKBOX:
            return (
                <AssetEditCheckBoxInput
                    key={field}
                    value={get(value, field)}
                    onChange={onChange}
                    label={label}
                    field={field}
                />
            );
        case InputPickerTypes.DATE:
            return (
                <AssetEditDateInput
                    key={field}
                    value={get(value, field)}
                    onChange={onChange}
                    required={required}
                    fullWidth={fullWidth}
                    label={label}
                    field={field}
                />
            );
        case InputPickerTypes.FILE:
            return (
                <FileInput
                    key={field}
                    value={get(value, field)}
                    changeRef={ref}
                    onChange={onChange}
                    required={required}
                    label={label}
                    fileTypes={fileTypes}
                    field={field}
                />
            );
        case InputPickerTypes.BLANK:
            return (
                <div key={field} className="half-width">
                    &nbsp;
                </div>
            );
        default:
            return null;
    }
}
