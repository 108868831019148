/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { Component } from 'react';
import { Select, SelectOption } from 'sarsaparilla';
import {
    getCssClassesFromProps,
    getLabelFromProps,
    getValidationCssClassesFromProps,
    getValidationListFromProps,
    IWrappedInputsBaseProps,
} from './wrappedInputs';
import '../../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';

interface SelectInputProps extends IWrappedInputsBaseProps {
    label: string;
    fullWidth?: boolean;
    options?: Array<{ label: string; value: string } & any>;
    value?: string;
    size?: number;
}

class AssetEditSelectInput extends Component<SelectInputProps> {
    componentDidUpdate() {
        const { options, value, onChange, field } = this.props;
        if (!value && options && options.length === 1) {
            onChange({ target: { dataset: { field }, value: options[0].value } });
        }
    }

    render() {
        const {
            size = null,
            field,
            validation: propsValidation = [],
            required,
            fullWidth,
            label: propsLabel,
            onChange,
            value = '',
            options,
        } = this.props;
        const id = `mod_${field.replace('.', '_')}`;
        const label = getLabelFromProps(propsLabel, id);
        const validation = getValidationListFromProps(propsValidation);
        const validationCSS = getValidationCssClassesFromProps(propsValidation);
        const css = getCssClassesFromProps(required, fullWidth, validationCSS);

        return (
            <div className={css}>
                {label}
                <Select
                    label=""
                    id={id}
                    data-field={field}
                    name={field}
                    value={value}
                    onChange={onChange}
                    size={size}
                >
                    {options.map((o) => (
                        <SelectOption label="" key={o.value} value={o.value}>
                            {o.label}
                        </SelectOption>
                    ))}
                </Select>
                {validation}
            </div>
        );
    }
}

export default AssetEditSelectInput;
