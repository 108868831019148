/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { get } from 'lodash';
import { accountDefaults, apiKeyDefaults } from '../shared/constants/auth';
import { asyncDefaults, makeAsyncReducer, mergeReducers } from '../shared/utils/async';
import { keysToCamelCase } from '../shared/utils/object';
import {
    ENABLE_DEVELOPER_ACCESS,
    FETCH_ACCOUNT,
    RIDB_ACTION_PREFIX,
    UPDATE_ACCOUNT,
} from '../shared/constants/actions';

const initialData = () => ({
    apiKey: apiKeyDefaults,
    account: accountDefaults,
});

const fetchAccount = makeAsyncReducer(FETCH_ACCOUNT, {
    success(state, { payload }) {
        return {
            loading: false,
            loaded: true,
            data: keysToCamelCase(payload),
            error: null,
        };
    },
    failure(state, { error }) {
        return { loading: false, loaded: false, data: initialData(), error };
    },
});

const enableDeveloperAccess = makeAsyncReducer(ENABLE_DEVELOPER_ACCESS, {
    // On success we're going to get back a boolean; Make no changes to the state
    success: ({ data }) => ({ loading: false, loaded: true, error: null, data }),
});

const updateAccount = makeAsyncReducer(UPDATE_ACCOUNT, {
    // On success we're going to get back a boolean; Make no changes to the state
    success: ({ data }) => ({ loading: false, loaded: true, error: null, data }),
});

export default mergeReducers(
    asyncDefaults(initialData()),
    RIDB_ACTION_PREFIX,
    fetchAccount,
    enableDeveloperAccess,
    updateAccount
);

export const getAccount = (state) => get(state, 'portal.accounts.data');
