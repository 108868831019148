/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { IAssetDisplayFields } from '../../types/asset/asset';

export const decodeFacilityActivityKeys = {
    ActivityID: 'activityId',
    ActivityParentID: 'parentActivityId',
    FacilityID: 'assetId',
    ActivityName: 'name',
    FacilityActivityDescription: 'description',
    FacilityActivityFeeDescription: 'feeDescription',
};

export const encodeFacilityActivityKeys = {
    activityId: 'activityID',
    assetId: 'facilityID',
};

export const decodeFacilityAddressKeys = {
    FacilityAddressID: 'id',
    FacilityID: 'assetId',
    FacilityAddressType: 'type',
    FacilityStreetAddress1: 'address1',
    FacilityStreetAddress2: 'address2',
    FacilityStreetAddress3: 'address3',
    City: 'city',
    PostalCode: 'postalCode',
    AddressStateCode: 'stateCode',
    AddressCountryCode: 'countryCode',
    LastUpdatedDate: 'lastUpdatedDate',
};

export const encodeFacilityAddressKeys = {
    id: 'facilityAddressID',
    assetId: 'facilityID',
    type: 'facilityAddressType',
    address1: 'facilityStreetAddress1',
    address2: 'facilityStreetAddress2',
    address3: 'facilityStreetAddress3',
    city: 'city',
    postalCode: 'postalCode',
    stateCode: 'addressStateCode',
    countryCode: 'addressCountryCode',
};

export const facilityDecodeKeys = {
    FacilityID: 'id',
    LegacyFacilityID: 'legacyId',
    OrgFacilityID: 'orgRefId',
    ParentOrgID: 'orgId',
    ParentRecAreaID: 'recAreaId',
    FacilityName: 'name',
    FacilityDescription: 'description',
    FacilityTypeDescription: 'typeDescription',
    FacilityUseFeeDescription: 'feeDescription',
    FacilityDirections: 'directions',
    FacilityAccessibilityText: 'accessibilityText',
    FacilityPhone: 'phone',
    FacilityEmail: 'email',
    FacilityReservationURL: 'reservationUrl',
    FacilityMapURL: 'mapUrl',
    FacilityAdaAccess: 'adaAccess',
    FacilityLongitude: 'longitude',
    FacilityLatitude: 'latitude',
    StayLimit: 'stayLimit',
    Keywords: 'keywords',
    Enabled: 'enabled',
    Reservable: 'reservable',
    LastUpdatedDate: 'lastUpdatedDate',
};

export const facilityEncodeKeys = {
    id: 'facilityID',
    orgId: 'orgID',
    recAreaId: 'parentRecAreaID',
    orgRefId: 'legacyFacilityIDOrg',
    name: 'facilityName',
    description: 'facilityDescription',
    typeDescription: 'facilityTypeDescription',
    feeDescription: 'facilityUseFeeDescription',
    directions: 'facilityDirections',
    accessibilityText: 'facilityAccessibilityText',
    phone: 'facilityPhone',
    email: 'facilityEmail',
    reservationUrl: 'facilityReservationURL',
    mapUrl: 'facilityMapURL',
    adaAccess: 'facilityAdaAccess',
    longitude: 'facilityLongitude',
    latitude: 'facilityLatitude',
    stayLimit: 'stayLimit',
    enabled: 'enabled',
    reservable: 'reservable',
    keywords: 'keywords',
};

export const facilityRecordMappings = {
    facilityAdaAccess: (val) => {
        if (val === false) {
            return 'No';
        }
        if (val === true) {
            return 'Yes';
        }
        return val;
    },
};

export const facilityAssetTypeDetails = {
    name: 'Facility',
    longName: 'Facility',
    singular: 'facility',
    plural: 'facilities',
    activity: 'facilityactivity',
    activities: 'facilityactivities',
    address: 'facilityaddreess',
    addresses: 'facilityaddresses',
    link: 'facilitylink',
    links: 'facilitylinks',
    media: 'facilitymedia',
    event: 'facilityevent',
    events: 'facilityevents',
};

export const facilityDisplayFields: IAssetDisplayFields[] = [
    {
        label: 'Facility Name',
        field: 'name',
    },
    {
        label: 'Facility ID',
        field: 'id',
    },
    {
        label: 'Email',
        field: 'email',
    },
    {
        label: 'Fee Description',
        field: 'feeDescription',
    },
    {
        label: 'Legacy ID',
        field: 'legacyId',
    },
    {
        label: 'Legacy ID Organization',
        field: 'legacyIdOrg',
    },
    {
        label: 'Latitude',
        field: 'latitude',
    },
    {
        label: 'Longitude',
        field: 'longitude',
    },
    {
        label: 'Phone',
        field: 'phone',
    },
    {
        label: 'Enabled',
        field: 'enabled',
    },
    {
        label: 'Facility ADA Access',
        field: 'adaAccess',
    },
    {
        label: 'Parent Rec Area',
        field: 'recareas[0].name',
    },
    {
        label: '',
        field: '',
    },
    {
        label: 'Organization',
        field: 'organizations',
        arrayIdField: 'id',
        arrayFields: ['name'],
    },
    {
        label: 'Activities',
        field: 'activities',
        arrayIdField: 'activityId',
        arrayFields: ['name'],
    },
    {
        label: 'Description',
        fullWidth: true,
        field: 'description',
    },
    {
        label: 'Directions',
        fullWidth: true,
        field: 'directions',
    },
    {
        label: `ADA Accessibility Info`,
        fullWidth: true,
        field: 'accessibilityText',
    },
    {
        label: 'Keywords',
        fullWidth: true,
        field: 'keywords',
    },
    {
        label: 'Stay Limit',
        fullWidth: true,
        field: 'stayLimit',
    },
    {
        label: 'Addresses',
        fullWidth: true,
        field: 'addresses',
        arrayIdField: 'id',
        arrayFields: [
            'address1',
            'address2',
            'address3',
            'city',
            'stateCode',
            'postalCode',
            'countyCode',
        ],
    },
];

export const FACILITY_URL = 'facility';
