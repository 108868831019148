/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button,
    HelmetWrapper,
    Label,
    SkipNavContent,
    TextField,
    TextBlock,
} from 'sarsaparilla';
import { getCurrentUser } from '../reducers/currentUser';
import { Globals } from '../shared/globals';
import AlertsContainer from '../shared/containers/AlertsContainer';
import { login } from '../actions/currentUser';
import AnyFunction = Globals.AnyFunction;
import TargetedEvent = Globals.TargetedEvent;
import signUpUrl = Globals.signUpUrl;
import forgotUrl = Globals.forgotUrl;
import { addAlert } from '../actions/alerts';
import redirect = Globals.redirect;
import '../scss/containers/_LoginPage.scss';

type LoginPageProps = {
    history?: any;
    currentUser: any;
    addAlert: AnyFunction;
    login: AnyFunction;
};

function LoginPage(props: LoginPageProps) {
    const [loading, setLoading] = useState(false);
    const [username, setUsernameState] = useState('');
    const [password, setPasswordState] = useState('');

    const setEmail = (event: TargetedEvent) => {
        const { value } = event.target;
        setUsernameState(value);
    };

    const setPassword = (event: TargetedEvent) => {
        const { value } = event.target;
        setPasswordState(value);
    };

    const goLogin = () => {
        const { login, history, addAlert } = props;
        if (loading) {
            return;
        }
        setLoading(true);
        const params = { username, password };
        login(params)
            .then(() => redirect(history, '/'))
            .catch(({ message }) => {
                switch (message) {
                    case 'mfa':
                        addAlert({
                            level: 'warning',
                            title: 'Unrecognized Device',
                            message:
                                "We don't recognize the device you're trying to login from. " +
                                'Please check your email to verify your identity and authorize this device.',
                        });
                        break;
                    default:
                        addAlert({
                            title: 'Login Trouble',
                            message:
                                'Could not log you in. Check your email and password or try again later.',
                        });
                }
            })
            .finally(() => setLoading(false));
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            goLogin();
        }
    };

    return (
        <SkipNavContent>
            <HelmetWrapper title="RIDB Login" />
            <main role="main">
                <div className="login-page" onKeyPress={handleKeyPress}>
                    <div className="alerts-box">
                        <AlertsContainer group="" />
                    </div>
                    <div className="modal-box">
                        <h1>Sign In</h1>
                        <TextBlock className="login-text-block">
                            <strong>
                                Use your Recreation.gov account email to Sign In.
                            </strong>
                        </TextBlock>
                        <TextBlock className="login-text-block">
                            If you do not already have an account, visit{' '}
                            <a href={signUpUrl}>Recreation.gov</a> to sign up for one.
                        </TextBlock>
                        <form>
                            <Label htmlFor="login-email">
                                <a href={signUpUrl}>Recreation.gov</a> Email
                            </Label>
                            <TextField
                                label=""
                                id="login-email"
                                type="text"
                                onChange={setEmail}
                            />
                            <Label htmlFor="login-password">Password</Label>
                            <TextField
                                label=""
                                id="login-password"
                                type="password"
                                onChange={setPassword}
                            />
                            <TextBlock className="login-text-block m-top-18">
                                <a href={forgotUrl}>Forgot Password?</a>
                            </TextBlock>
                            <TextBlock className="login-text-block">
                                <Button onClick={goLogin}>Sign In</Button>
                            </TextBlock>
                        </form>
                    </div>
                </div>
            </main>
        </SkipNavContent>
    );
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            addAlert,
            login,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
