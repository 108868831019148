/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get, isEqual } from 'lodash';
import memoize from 'memoize-one';
import { BlockTabs, Button, ButtonGroup, Notification, Panel } from 'sarsaparilla';
import SaveStatus from './SaveStatus';
import { getAsset } from '../../../reducers/asset';
import { getOrganization } from '../../../reducers/organizations';
import { getCurrentUser } from '../../../reducers/currentUser';
import { getState } from '../../../reducers';
import { getChangeCount, getErrorCount } from '../../../reducers/changes';
import { StateStatus } from '../../../shared/utils/async';
import { fetchOrganizations } from '../../../actions/organization';
import { fetchAsset, resetAsset } from '../../../actions/asset';
import { addChange, getRef, removeRefs, saveChanges } from '../../../actions/changes';
import { saveClearErrors } from '../../../actions/saveStatus';
import { flattenOrg, orgsToOptions } from '../../../shared/utils/organization';
import {
    determineAssetInvalidItems,
    getAssetTypeDetails,
} from '../../../shared/utils/asset';
import AssetTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/AssetTab';
import ActivitiesTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/ActivitiesTab';
import AddressesTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/AddressesTab';
import LinkTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/LinkTab';
import ImagesTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/ImagesTab';
import EventsTab from '../../../components/StewardAsset/StewardAssetEdit/tabs/EventsTab';
import { Globals } from '../../../shared/globals';
import AnyObject = Globals.AnyObject;
import redirect = Globals.redirect;
import {
    StewardAssetInvalidItem,
    StewardAssetTabType,
} from '../../../shared/types/asset/asset';
import '../../../scss/containers/StewardAssetEditPage/_StewardAssetEditPage.scss';
import AnyFunction = Globals.AnyFunction;
import mergeDefaults = Globals.mergeDefaults;
import mergeDefaultsPermitEmptyString = Globals.mergeDefaultsPermitEmptyString;
import { getNewChanges } from '../../../shared/constants/asset/asset';

function StewardAssetEditPage({ match }) {
    const [isProgressOpen, toggleProgressModal] = useState(false);
    const [assetLastRef, updateAssetLastRef] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();

    const asset = useSelector((state) => getAsset(state));
    const [ref, updateRef] = useState(void 0);
    const [assetInState, updateAssetInState]: [any, AnyFunction] = useState({});
    const [dispatchedChanges, updateDispatchedChanges] = useState([]);
    const organization = useSelector((state) =>
        getOrganization(state, getCurrentUser(state).apiKey.orgId)
    );
    const states: AnyObject = useSelector((state) =>
        getState(state, 'organizations', 'currentUser', 'asset')
    );
    const changes = useSelector((state: any) => state.portal.changes);
    const changeCount = useSelector((state: any) => getChangeCount(state));
    const errorCount = useSelector((state: any) => getErrorCount(state));
    const [allInvalidFields, updateAllInvalidFields] = useState([]);
    const {
        params: { assetId, assetType },
    } = match;
    const details = getAssetTypeDetails(assetType);
    const { organizations } = states;

    useEffect(() => {
        const {
            params: { assetId, assetType },
        } = match;

        if (organizations === StateStatus.UNLOADED) {
            dispatch(fetchOrganizations());
        }

        if (assetId) {
            const doesAssetNeedUpdate = assetId && get(asset, 'id', '') !== assetId;
            if (doesAssetNeedUpdate) {
                dispatch(fetchAsset(assetType, assetId, { full: true }));
            }
            if (!ref) {
                const newRef = dispatch(getRef(details.plural, { id: assetId }, -1, []));
                updateRef(newRef);
            }
        }

        return () => {
            dispatch(removeRefs());
            dispatch(saveClearErrors());
        };
    }, []);

    useEffect(() => {
        if (Object.keys(asset).length !== 0 && stateAsset === StateStatus.READY) {
            updateAssetInState(asset);
        }
    }, [asset]);

    useEffect(() => {
        const changes = getNewChanges(asset, assetInState);
        changes.forEach((c) => {
            if (dispatchedChanges.filter((change) => isEqual(change, c)).length < 1) {
                dispatch(addChange(ref, c));
                updateDispatchedChanges([...dispatchedChanges, c]);
            }
        });
    }, [assetInState]);

    const getOrgOptions = memoize((organizationsState, currentUserState) =>
        organizationsState === StateStatus.READY &&
        currentUserState === StateStatus.READY &&
        organization
            ? orgsToOptions(flattenOrg(organization))
            : []
    );

    const handleCancel = () => {
        const { assetType } = match.params;
        redirect(history, `/data-steward/${assetType}/search`);
    };

    const handleAssetUpdate = (assetChange: any, tabType: StewardAssetTabType) => {
        switch (tabType) {
            case 'assets':
                const finalAsset = mergeDefaultsPermitEmptyString(
                    assetChange,
                    assetInState
                );
                updateAssetInState(finalAsset);
                break;
            case 'activities':
            case 'addresses':
            case 'media':
            case 'links':
            case 'events':
                const newState = { ...assetInState };
                delete newState[tabType];
                newState[tabType] = assetChange;
                updateAssetInState({ ...newState });
                break;
        }
    };

    const handleSave = () => {
        dispatch(saveClearErrors());
        const {
            params: { assetId },
        } = match;
        const { longitude: assetLongitude, latitude: assetLatitude } = asset;
        toggleProgressModal(true);
        // special case where if only 1 is provided, need to send back other for BE
        const changesToSend = changes
            .map((change) => {
                if (!change || !change.changes) {
                    return change;
                }
                const copy = { ...change };
                const { latitude: changesLatitude, longitude: changesLongitude } =
                    copy.changes;
                if (changesLatitude && !changesLongitude) {
                    copy.changes.longitude = assetLongitude.toString();
                }
                if (changesLongitude && !changesLatitude) {
                    copy.changes.latitude = assetLatitude.toString();
                }
                return copy;
            })
            .concat(allInvalidFields.filter((f) => f.invalid));
        const promise: any = dispatch(saveChanges(changesToSend, assetId, true));
        promise.then(() => dispatch(resetAsset())).catch((e) => console.error(e));
    };

    const handleInvalidItems = (invalidItems: StewardAssetInvalidItem[]) => {
        const finalItems = determineAssetInvalidItems(
            [...allInvalidFields],
            invalidItems
        );
        updateAllInvalidFields(finalItems.filter((i) => !i._delete));
    };

    const handleCloseModal = ({ shouldRedirect }) => {
        if (shouldRedirect) {
            redirect(history, `/data-steward/${assetType}/${assetId}/display`);
        } else {
            toggleProgressModal(false);
        }
        dispatch(saveClearErrors());
    };

    const handleLastAssetRef = (ref: number) => {
        const formattedRef = `ref-${ref}`;
        if (assetLastRef !== formattedRef) {
            updateAssetLastRef(formattedRef);
        }
    };

    const {
        organizations: organizationsState,
        currentUser: currentUserState,
        asset: stateAsset,
    } = states;

    const orgOptions = getOrgOptions(organizationsState, currentUserState);
    const loading = stateAsset !== StateStatus.READY || assetId !== asset.id;
    const reservable = get(asset, 'reservable', false);
    const { name, activities, addresses, links, media, event } = details;
    const savedActivityIds = (asset.activities || []).map((a) => a.activityId);
    return (
        <div className="steward-asset-edit">
            {reservable && (
                <Notification title="Warning:" type="warning">
                    This {name} is not editable.
                </Notification>
            )}

            {isProgressOpen && <SaveStatus onClose={handleCloseModal} />}

            <BlockTabs indentTabs>
                <Panel label={name} className="asset-tab">
                    {ref && (
                        <AssetTab
                            lastRef={assetLastRef}
                            onLastAssetRef={handleLastAssetRef}
                            assetDetails={details}
                            value={assetInState}
                            orgOptions={orgOptions}
                            onAssetUpdate={handleAssetUpdate}
                            onInvalidItemsChange={handleInvalidItems}
                            isLoading={loading}
                        />
                    )}
                </Panel>

                <Panel label="Activities" className="activities-tab">
                    <ActivitiesTab
                        savedActivityIds={savedActivityIds}
                        activityType={activities}
                        onAssetUpdate={handleAssetUpdate}
                        value={assetInState}
                        isLoading={loading}
                    />
                </Panel>

                <Panel label="Addresses" className="addresses-tab">
                    <AddressesTab
                        addressType={addresses}
                        onAssetUpdate={handleAssetUpdate}
                        value={assetInState}
                        isLoading={loading}
                    />
                </Panel>

                <Panel label="Links" className="links-tab">
                    <LinkTab
                        linkType={links}
                        onAssetUpdate={handleAssetUpdate}
                        value={assetInState}
                        onInvalidItemsChange={handleInvalidItems}
                        isLoading={loading}
                    />
                </Panel>

                <Panel label="Images" className="images-tab">
                    <ImagesTab
                        mediaType={media}
                        onAssetUpdate={handleAssetUpdate}
                        onInvalidItemsChange={handleInvalidItems}
                        value={assetInState}
                        isLoading={loading}
                    />
                </Panel>

                <Panel label="Events" className="events-tab">
                    <EventsTab
                        eventType={event}
                        assetType={assetType}
                        onAssetUpdate={handleAssetUpdate}
                        onInvalidItemsChange={handleInvalidItems}
                        eventsToLoad={assetInState ? assetInState.eventsToLoad : false}
                        asset={assetInState}
                        isLoading={loading}
                    />
                </Panel>
            </BlockTabs>

            <div className="form-footer">
                <div className="footer-status">
                    STATUS: {changeCount} Changes, {errorCount} Errors
                </div>

                <ButtonGroup>
                    <Button appearance="tertiary" onClick={handleCancel}>
                        Cancel
                    </Button>
                    {!reservable && (
                        <Button onClick={handleSave} isLoading={loading}>
                            Save Changes
                        </Button>
                    )}
                </ButtonGroup>
            </div>
        </div>
    );
}

export default StewardAssetEditPage;
