/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { makeAsyncAction } from '../shared/utils/async';
import API from '../shared/api';
import { GENERATE_APIKEY } from '../shared/constants/actions';

export const generateApiKey = makeAsyncAction(
    GENERATE_APIKEY,
    () => () => API.generateApiKey(),
    'api_key'
);
